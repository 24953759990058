import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../shared/components/Header/Header";

function AddPatient() {
    const [day, setDay] = useState();

    return (
        <div className="">
            <patients
                className="p-5 pt-7 "
             >
                <div className="m-7 xs:m-0 mt-10 mb-18 bg-white rounded-lg">
                    <div className="flex  p-6 pl-8  ">
                        <div className="">
                            <h2 className=" text-pulse-gray leading-6 font-medium text-xl ">
                                Add New Patient
                            </h2>
                        </div>
      
                    </div>
                    <div className="  mt-10 ml-8 xs:grid-cols-1 grid grid-cols-3">
                        <div className="col-span-1">
                            <h4 className="text-inputText-500 font-medium">Full Name *</h4>
                            <input className="bg-input-500 px-4 mt-4 rounded-xl  border-inputBorder-500 border-2 h-12 w-[300px]" required placeholder=" Enter Full Name">
                            </input>
                        </div>
                        <div className=" xs:mt-2 col-span-1">
                            <h4 className="text-inputText-500 font-medium">Guardian Name </h4>
                            <input className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2 h-12 w-[300px]"  placeholder="Enter Guardian Name">
                            </input>
                        </div>
                        <div className="xs:mt-2  col-span-1">
                            <h4 className="text-inputText-500 font-medium">Email </h4>
                            <input className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2 h-12 w-[300px]"  placeholder="Enter Email Address">
                            </input>
                        </div>
                        <div className=" mt-8 xs:mt-4 col-span-1">
                            <h4 className="text-inputText-500 xs:mb-2 font-medium">Gender *</h4>
                            <select className="bg-input-500 rounded-xl mt-4 px-4 border-inputBorder-500 border-2 h-12 w-[300px]" required placeholder="Select ">
                                <option value='select'>
                                    Select
                                </option>
                                <option className="placeholder:p-4 " value='Male'>

                                 Male
                                </option>
                                <option value='Female'>
                                 Female
                                </option>
                                <option value='Other'>
                                 Other
                                </option>


                            </select>
                        </div>
                        <div className=" xs:mt-4 mt-8 col-span-1">
                            <h4 className="text-inputText-500 font-medium">Mobile Number *</h4>
                            <input className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2 h-12 w-[300px]" required placeholder="Enter Mobile Number">
                            </input>
                        </div>
                        <div className=" mt-8 xs:mt-4  col-span-1">
                            <h4 className="text-inputText-500 font-medium">Marital status *</h4>
                            <select className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[300px]" required placeholder="Select ">
                                <option value='select'>
                                    Select
                                </option>
                                <option className="placeholder:p-4 " value='Male'>

                                Unmarried
                                </option>
                                <option value='Female'>
                                 Married
                                </option>
                                <option value='Other'>
                                 Other
                                </option>


                            </select>
                            </div>
                        <div className=" mt-8 xs:mt-4  col-span-1">
                            <h4 className="text-inputText-500 font-medium">Date of Birth *</h4>
                            <input type="date"
                                name="date"
                                id="date"
                                className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2 h-12 w-[300px]" required placeholder="dd/mm/yy">
                            </input>
                        </div>
                        
                        <div className=" mt-8 xs:mt-4 col-span-1">
                            <h4 className="text-inputText-500 font-medium">Address *</h4>
                            <input className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2 h-12 w-[300px]" required placeholder="Enter Address">
                            </input>
                        </div>
                        <div className=" mt-8 xs:mt-4 col-span-1">
                            <h4 className="text-inputText-500 font-medium">Amount </h4>
                            <input className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2 h-12 w-[300px]"  placeholder="Enter Amount">
                            </input>
                        </div>
                        <div className=" mt-8 xs:mt-4 col-span-1">
                            <h4 className="text-inputText-500 font-medium">Upload Documents (If Any)</h4>
                            <input
                                class="relative w-[300px]  mt-4 rounded-xl  m-0 block  min-w-0 flex-auto cursor-pointer  border border-solid border-neutral-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-green-500 file:px-3 file:py-3 file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-neutral-200 focus:border-primary focus:bg-white focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
                                type="file"
                                id="formFile" />
                        </div>
                    </div>


                    {/* Save/Cancel */}
                    <div className="flex m-4 mt-10 mx-8 mb-16 flex-wrap">
                        <button
                            type="submit"
                            className="flex m-2 cursor-pointer h-10  justify-between px-8 rounded-lg font-medium text-sm bg-[#209F84] text-white"
                        >
                            <span className="self-center m-auto">Save</span>
                        </button>

                        <Link to="/AddPatient">
                            <div className="flex m-2 cursor-pointer  h-10 w-28  justify-between px-5 rounded-lg font-medium text-sm border-2 border-[#209F84]">
                                <span className="self-center m-auto  text-[#209F84]">
                                    Cancel
                                </span>
                            </div>
                        </Link>
                    </div>
                </div>
            </patients>
        </div>
    );
}

export default AddPatient;
