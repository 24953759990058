import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../shared/components/Header/Header";

function EditAppointment() {
  const [day, setDay] = useState();


  return (
    <div className="">
     
      <appointments
        className="basis-10/12 p-5 pt-7 "
        id="dashboardPage"     >

        <div className="m-7 mt-10 mb-18 bg-white rounded-lg">
          <div className="flex justify-between p-6 pb-0 pl-8  ">
            <div className="">
              <h2 className=" text-pulse-gray leading-6 font-medium text-xl ">
                Edit Appointment
              </h2>
            </div>
          </div>
          <div className=" mx-40 mt-10 ml-8 grid grid-cols-2">
            <div className="col-span-1">
              <h4 className="text-inputText-500 font-medium">First Name</h4>
              <input className="bg-input-500 px-4 mt-4 rounded-xl  border-inputBorder-500 border-2 h-12 w-[400px]" required placeholder=" Enter First Name">
              </input>
            </div>
            <div className="  col-span-1">
              <h4 className="text-inputText-500 font-medium">Last Name</h4>
              <input className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2 h-12 w-[400px]" required placeholder="Enter Last Name">
              </input>
            </div>
          </div>
          <div className=" mx-40 mt-7 ml-8 grid grid-cols-2">
            <div className="col-span-1">
              <h4 className="text-inputText-500 font-medium">Gender</h4>
              <select  className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[400px]" required placeholder="Select ">
              <option  value='select'>
                Select
              </option>
              <option className="placeholder:p-4 " value='Male'>

                Male
              </option>
              <option value='Male'>
                Female
              </option>
              <option value='Male'>
                Other
              </option>
              </select>
            </div>
            <div className="  col-span-1">
              <h4 className="text-inputText-500 font-medium">Mobile Number</h4>
              <input className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[400px]" required placeholder=" Enter Mobile Number">
              </input>
            </div>
          </div>
          <div className=" mx-40 mt-7 ml-8 grid grid-cols-2">
            <div className="col-span-1">
              <h4 className="text-inputText-500 font-medium">Date Of Birth</h4>
              <input  required
                  type="date"
                  name="date"
                  id="date"
                  onChange={(e) => {
                    let dayArray = [
                      "Sunday",
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                    ];
                    setDay(dayArray[new Date(e.target.value).getDay()]);
                  }}  className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[400px]" >
              </input>
            </div>
            <div className="  col-span-1">
              <h4 className="text-inputText-500 font-medium">Email</h4>
              <input className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[400px]" type="email" required placeholder="Enter Email ">
              </input>
            </div>
          </div>
          <div className=" mx-40 mt-7 ml-8 grid grid-cols-1">
            <h4 className="text-inputText-500 font-medium">Your Address</h4>
            <input className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[600px]" required placeholder="Enter Address">
            </input>
          </div>
          <div className="flex justify-between p-6 pb-0 pl-8  ">
            <h2 className=" text-purple-600 leading-6 font-medium text-xl ">
               Appointment Details
            </h2>
          </div>
          <div className=" mx-40 mt-10 ml-8 grid grid-cols-2">
            <div className="col-span-1">
              <h4 className="text-inputText-500 font-medium">Date of Appointment</h4>
              <input  required
                  type="date"
                  name="date"
                  id="date"
                  onChange={(e) => {
                    let dayArray = [
                      "Sunday",
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                    ];
                    setDay(dayArray[new Date(e.target.value).getDay()]);
                  }}  className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[400px]" >
              </input>
            </div>
            <div className="  col-span-1">
              <h4 className="text-inputText-500 font-medium">Time of Appointment</h4>
              <input className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[400px]" type="time" required placeholder="Enter Time">
              </input>
            </div>
          </div>
          <div className=" mx-40 mt-7 ml-8 grid grid-cols-2">
            <div className="col-span-1">
              <h4 className="text-inputText-500 font-medium">Assign Doctor</h4>
              <input className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[400px]" required placeholder="Select ">
              </input>
            </div>
            <div className="  col-span-1">
              <h4 className="text-inputText-500 font-medium">Disease</h4>
              <input className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[400px]" required placeholder=" Enter Disease">
              </input>
            </div>
          </div>

          <div className=" mx-40 mt-7 ml-8 grid grid-cols-1">
            <h4 className="text-inputText-500 font-medium">Notes</h4>
            <input className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 w-[600px]" required placeholder=" Enter Notes">
            </input>
          </div>
           {/* Save/Cancel */}
           <div className="flex m-4 mt-10 mx-8 mb-16 flex-wrap">
              <button
                type="submit"
                className="flex m-2 cursor-pointer h-10 w-28 justify-between px-5 rounded-lg font-medium text-sm bg-[#209F84] text-white"
              >
                <span className="self-center m-auto">Submit</span>
              </button>

              <Link to="/AddAppointment">
                <div className="flex m-2 cursor-pointer h-10 w-28  justify-between px-5 rounded-lg font-medium text-sm border-2 border-[#209F84]">
                  <span className="self-center m-auto text-[#209F84]">
                    Cancel
                  </span>
                </div>
              </Link>
            </div>
        </div>
      </appointments>
    </div>
  );
}

export default EditAppointment;
