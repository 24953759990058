import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import {
  LockOpenOutlined,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    background: `url('/envelope-icon.svg') no-repeat left center`,
    backgroundSize: "20px 20px",
    // border: "1px solid rgba(181, 26, 138, 0.34)",
    // borderRadius: "8px",
  },
  icon: {
    color: "rgba(8, 33, 97, 0.56)",
  },

  textField: {
    "& .MuiOutlinedInput-root": {
      //   boxShadow: "-1px 12px 15px -1px rgba(0,0,0,0.36)",
      "& fieldset": {
        border: "1px solid rgba(181, 26, 138, 0.34)",
        borderRadius: "10px",
      },
      "&:hover fieldset": {
        border: "1px solid rgba(181, 26, 138, 0.34)",
        borderRadius: "10px",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(181, 26, 138, 0.34)",
        borderRadius: "10px",
      },
    },
  },
}));

const CreateNewPass = () => {
  const classes = useStyles();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // const handleTogglePasswordVisibility = () => {
  //   setShowConfirmPassword(false); // turn off confirm password visibility
  //   setShowPassword(!showPassword);
  // };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowPassword(false); // turn off password visibility
    setShowConfirmPassword(!showConfirmPassword);
    
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  return (
    <>
      <div className="w-screen h-screen flex flex-wrap justify-evenly items-center">
        <div className="w-4/5 h-4/5 m-auto flex flex-wrap justify-evenly items-center">
          <div>
            <img src="image/forgot.svg" alt="login" />
          </div>
          <div className=" grid p-8 w-2/5 mb-auto mt-2">
            <div className="flex justify-start">
              <h2 className="text-green-500 font-semibold text-xl tracking-wider">
                Create New Password
              </h2>
            </div>
            <div>
              <p className="text-light-100 text-xs mt-2">
                Your new password must be different from previous used password
              </p>
            </div>
            <div className="grid mt-6 ">
              <h4 className="text-light-100 my-2">Password</h4>
              <TextField
                variant="outlined"
                type={showConfirmPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                notched
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOpenOutlined className={classes.icon} />
                    </InputAdornment>
                  ),
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <IconButton onClick={handleTogglePasswordVisibility}>
                  //       {showPassword ? <Visibility /> : <VisibilityOff />}
                  //     </IconButton>
                  //   </InputAdornment>
                  // ),
                  classes: { input: classes.input },
                }}
              />

              <h4 className="text-light-100 my-2">Confirm Password</h4>
              <TextField
                variant="outlined"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                notched
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOpenOutlined className={classes.icon} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleToggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: { input: classes.input },
                }}
              />

              <button
                onClick={() => console.log(password, confirmPassword)}
                className="bg-green-500 w-full font-medium mt-8 mb-2 p-4 text-white rounded-xl"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewPass;
