import React ,{useState} from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table'
function PrescriptionTemplate() {
    const defaultMaterialTheme = createTheme();

    const columns = [
        { title: " Medicine Name",  field: "MedicineName",  },
        { title: "Dose", field: "Dose", },
        { title: "Duration", field: "BookingDate",  },
     
      ]

      const [tableData, setTableData] = useState([
        { MedicineName: "Jenaric", Dose: "1 morning. 1 Night", Status: "Pending", BookingDate: "10 Days", Diseas: "Fever", },
        { MedicineName: "Paracitamol", Dose: "1 morning. 1 Night", Status: "Pending", BookingDate: "10 Days", Diseas: "Fever", },
        { MedicineName: "Jenaric", Dose: "1 morning. 1 Night", Status: "Cancel", BookingDate: "10 Days", Diseas: "Fever", },
        { MedicineName: "Jenaric", Dose: "1 morning. 1 Night", Status: "Complete", BookingDate: "10 Days" , Diseas: "Fever", },
        { MedicineName: "Paracitamol", Dose: "1 morning. 1 Night", Status: "Complete", BookingDate: "10 Days", Diseas: "Fever", },
  
      ])

    // const PrescriptionDta = [
    //     {
    //         id: 1,
    //         MedicineName: "Generic",
    //         Dose: "1 morning. 1 Night ",
    //         Duration: "10 Days",
    //     },
    //     {
    //         id: 2,
    //         MedicineName: "Generic",
    //         Dose: "1 morning. 1 Night ",
    //         Duration: "10 Days",
    //     },
    //     {
    //         id: 3,
    //         MedicineName: "Generic",
    //         Dose: "1 morning. 1 Night ",
    //         Duration: "10 Days",
    //     },

    //     {
    //         id: 4,
    //         MedicineName: "Generic",
    //         Dose: "1 morning. 1 Night ",
    //         Duration: "10 Days",
    //     },
    // ];
    return (
        <div className='mx-10  overflow-x-scroll xs:mx-0 pb-4'>
            <div className='pb-4 pt-4 mt-4 border-2  '>
                <div className='flex pl-8 '>
                    <div className=''>
                        <img className='w-20 xs:w-10 ' src='image/demoLogo.png'></img>
                    </div>
                    <div className=' xs:ml-6 ml-10'>
                        <h2 className='text-lg xs:text-sm text-[#209F84] font-semibold font-serif'>Dr. Media Heading</h2>
                        <h4 className='xs:text-xs'>Mob. No: 8956854748</h4>
                    </div>
                    <div className=' xs:ml-28 ml-[340px]'>
                        <h2 className='text-xl text-[#209F84] font-semibold font-serif'>Care Clinic</h2>
                        <h4 >Near Axis Bank Kothurd Pune - 411038.</h4>
                        <h4>ph: 094751455</h4>
                        <h4>Timing: 09:00AM - 02:00AM</h4>
                    </div>
                </div>
                <div className='border-2 mt-6 mx-8  border-gray-400'></div>
                <div className='mt-6 flex'>
                    <div className=' ml-20'>
                        <h2 className='text-lg font-semibold font-serif'> Prescription No: 04 </h2>
                        <h4 className='py-1'>Patient ID<span className='ml-4'>: 266</span></h4>
                        <h4>Patient Name<span className='ml-2'>: jhon doe</span></h4>
                    </div>
                    <div className=' ml-32'>
                        <h2 className='  '>Age <span className='ml-10'>: 3 year</span> </h2>
                        <h4 className='py-1'>gender<span className='ml-5'>: male</span> </h4>
                        <h4>Address<span className='ml-3'>: Near Axis Bank Ludhiyana Punjab</span></h4>
                    </div>
                    <div className=' ml-8'>
                        <h2 className=' '>Date <span className='ml-10'>: 28/10/2022</span> </h2>
                        <h4 className='py-1'>Next Visit<span className='ml-5'>: 04/11/2022</span> </h4>
                    </div>
                </div>
                <div className='border-2 mt-4 mx-8  border-gray-400'></div>
                {/* <div className='mt-4 mx-8' >
                    <div className='flex border-y-2  border-gray-400 font-[700px] pl-8 py-3 gap-80'>
                        <h4>Medicine Name</h4>
                        <h4>Dosage</h4>
                        <h4>Duration</h4>
                    </div>
                    <div className='border-b-2 pb-4'>
                        {PrescriptionDta.map((item) => {
                            return (
                                <div className='flex pl-8 py-[10px] xs:gap-40 gap-80'>
                                    <p>{item.MedicineName}</p>
                                    <p>{item.Dose}</p>
                                    <p>{item.Duration}</p>
                                </div>)
                        })}
                    </div>
                </div> */}  <div className='mx-8'><ThemeProvider  theme={defaultMaterialTheme}>
            <MaterialTable columns={columns} data={tableData}  title = {false}  options={{exportButton: false ,    search: false, paginationPosition:false, headerStyle: { background: "#F3F6F9",color:"#2B3B53",border: "none" , fontSize:"16px"}, cellStyle: {
              border:"none"
            },  }}
           />
        </ThemeProvider></div>
                <div className='mt-4 ml-8'>
                    <span>Remark Notes :</span> <input className='ml-6 bg-gray-100 h-10 px-4 rounded-sm' type="text" placeholder='Enter Any remarks here'></input>
                    <button className='ml-[440px] bg-[#209F84] px-3 py-2 rounded-md text-white'>Save And Print</button>
                </div>
            </div>

        </div>
    )
}

export default PrescriptionTemplate