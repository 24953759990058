import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    background: `url('/envelope-icon.svg') no-repeat left center`,
    backgroundSize: "20px 20px",
    // border: "1px solid rgba(181, 26, 138, 0.34)",
    // borderRadius: "8px",
  },
  icon: {
    color: "rgba(8, 33, 97, 0.56)",
  },

  textField: {
    "& .MuiOutlinedInput-root": {
      //   boxShadow: "-1px 12px 15px -1px rgba(0,0,0,0.36)",
      "& fieldset": {
        border: "1px solid rgba(181, 26, 138, 0.34)",
        borderRadius: "10px",
      },
      "&:hover fieldset": {
        border: "1px solid rgba(181, 26, 138, 0.34)",
        borderRadius: "10px",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(181, 26, 138, 0.34)",
        borderRadius: "10px",
      },
    },
  },
}));

const VerificationCode = () => {
  const classes = useStyles();

  return (
    <>
      <div className="w-screen h-screen flex flex-wrap justify-around items-center">
        <div className="w-4/5 h-4/5 m-auto flex flex-wrap justify-around items-center">
          <div>
            <img src="image/forgot.svg" alt="login" />
          </div>
          <div className=" grid p-8 w-2/5 mb-auto mt-4">
            <div className="flex justify-start">
              <h2 className="text-green-500 font-semibold text-xl tracking-wider">
                Reset Your Password
              </h2>
            </div>
            <div>
              <p className="text-light-100 text-xs mt-6">
                Enter the verification code we just sent to your <br /> email
                address
              </p>
            </div>
            <div className="grid mt-4 ">
              <h4 className="text-light-100 my-2">Enter Verification Code</h4>
              <TextField
                variant="outlined"
                notched
                className={classes.textField}
                
              />

              <button className="bg-green-500 w-full font-medium mt-8 mb-2 p-4 text-white rounded-xl">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationCode;
