import React from 'react'
import Header from '../../shared/components/Header/Header'
import AppointmentStatusChart from './AppointmentStatusChart'
import PatientStatus_Chart from './PatientStatusChart'
import StatusCard from './StatusCard'


function Dashboard() {
    return (
        <div className=''>
           
            <div>
                <welcome>
                    <h2 className=" pl-14 mt-14 font-medium text-2xl text-green-500 mx-3">
                        Welcome to !
                    </h2>
                </welcome>
                <div><StatusCard /></div>

                <div className='xs:hidden'>
                    <PatientStatus_Chart />
                </div>
                <div className='xs:hidden'>
                    <AppointmentStatusChart />
                </div>

            </div>
        </div>
    )
}

export default Dashboard