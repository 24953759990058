import MaterialTable from 'material-table'
import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { ThemeProvider, createTheme } from '@mui/material';
import Delete from '@material-ui/icons/Delete';

function Patient() {
  const defaultMaterialTheme = createTheme();

  const columns = [
    { title: "Id", field: "id", editable: false, render: (rowData) => <span className='text-[#2781D5]'>{rowData.id}</span> },
    { title: "Patient Name", field: "Patient_Name", },
    { title: "Phone", field: "phone", },
    { title: "Address", field: "Address", searchable: false },
    { title: "Diseas", field: "Diseas" },
    { title: "Status", field: "Status", render: (rowData) => <div className='rounded-md w-32 text-sm text-center py-1 text-white' style={{ backgroundColor: rowData.Status == "Cancel" ? "#FFDDE4" : rowData.Status == "Pending" ? "#FFF6E0" : "#C9F7F5", color: rowData.Status == "Cancel" ? "red" : rowData.Status == "Pending" ? "orange" : "#1BC5BD" }} >{rowData.Status}</div> },
  ]

  const [tableData, setTableData] = useState([
    { id: "#1", Patient_Name: "Rahul", Status: "Pending", phone: "9857463258", Diseas: "Fever", Address: "behind laxmi nagar ujjain mp", },
    { id: "#2", Patient_Name: "Vijay", Status: "Pending", phone: "9857463258", Diseas: "Fever", Address: "behind laxmi nagar ujjain mp", },
    { id: "#3", Patient_Name: "Vikash", Status: "Cancel", phone: "9857463258", Diseas: "Fever", Address: "behind laxmi nagar ujjain mp", },
    { id: "#4", Patient_Name: "Rahul", Status: "Complete", phone: "9857463258", Diseas: "Fever", Address: "behind laxmi nagar ujjain mp", },
    { id: "#5", Patient_Name: "Rahul", Status: "Complete", phone: "9857463258", Diseas: "Fever", Address: "behind laxmi nagar ujjain mp", },

  ])
  return (
    <div className="mt-8 mx-6">
      <div className=" xs:col-span-1 col-span-8">
        <a
          href="/AddPatient"
          className="ml-6 rounded-lg bg-[#209F84] hover:bg-[#1B846D] px-3 py-2 text-white"
        >
          +  Patient
        </a>
      </div>

      <div className='mt-8'>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable columns={columns} data={tableData} title="All Patient" editable={{
            onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
              const updatedData = [...tableData]
              updatedData[oldRow.tableData.id] = newRow
              setTableData(updatedData)
              setTimeout(() => resolve(), 500)
            }),
            onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
              const updatedData = [...tableData]
              updatedData.splice(selectedRow.tableData.id, 1)
              setTableData(updatedData)
              setTimeout(() => resolve(), 1000)
            })
          }}
            options={{ exportButton: true, headerStyle: { background: "#F3F6F9", color: "#2B3B53", fontSize: "16px", }, actionsColumnIndex: -1, actionsCellStyle: { marginRight: "10px" } }}

            icons={{ Delete: () => <img src='image/deleteicone.svg'></img>, Edit: () => <img src='image/editicone.svg'></img>, }}
          />
        </ThemeProvider>

      </div></div>
  )
}

export default Patient