import React from "react";
import { useState } from "react";
import Header from "../../shared/components/Header/Header";
import AppointmentProfile from "./components/AppointmentProfile";
import BillingProfile from "./components/BillingProfile";
import MedicalRecordProfile from "./components/MedicalRecordProfile";
import PatientDetails from "./components/PatientDetails";
import PrescriptionProfile from "./components/PrescriptionProfile";
import "./PatientProfile.css";

function PatientProfile() {
  // tab functionality
  const [tabState, setTabState] = useState(0);
  const [tabComp, setTabComp] = useState(0);

  const [tabComponents, setTabComponents] = useState([
    [<AppointmentProfile setTabComp={setTabComp} />],
    [<PrescriptionProfile setTabComp={setTabComp} />],
    [<MedicalRecordProfile setTabComp={setTabComp} />],
    [<BillingProfile setTabComp={setTabComp} />],
  ]);
  return (
    <div>
      <div className="grid xs:grid-cols-1 grid-cols-4 mt-10">
        <div className="md:hidden bg-white"><PatientDetails /></div>
        <div className="col-span-3 h-[600px] bg-white mr-6 xs:mr-0  xs:ml-0 ml-6 ">
          <div>
            <ul className="flex gap-[120px] xs:gap-8 shadow-sm  border-b py-4 xs:pl-2 pl-8 xs:mt-8 mt-0">
              <li className={"hover:text-[#711685] xs:text-xs cursor-pointer " + (tabState === 0 ? "tabs" : "")}
                onClick={() => {
                  setTabState(0);
                  setTabComp(0);
                }}
              >
                Appointments
              </li>
              <li className={"hover:text-[#711685] xs:text-xs cursor-pointer " + (tabState === 1 ? "tabs" : "")}
                onClick={() => {
                  setTabState(1);
                  setTabComp(0);
                }}
              >
                {" "}
                Prescription
              </li>
              <li className={"hover:text-[#711685] xs:text-xs cursor-pointer " + (tabState === 2 ? "tabs" : "")}
                onClick={() => {
                  setTabState(2);
                  setTabComp(0);
                }}
              >
                Medical Record
              </li>
              <li className={"hover:text-[#711685] xs:text-xs cursor-pointer " + (tabState === 3 ? "tabs" : "")}
                onClick={() => {
                  setTabState(3);
                  setTabComp(0);
                }}
              >
                Billing
              </li>
            </ul>
          </div>
          {tabComponents[tabState][tabComp]}
          {/* <div id="hi" onClick={setTabComponents}></div> */}
        </div>
        <div className="col-span-1 xs:hidden mr-4 h-[600px] bg-white">
          {/* <div className="mt-4 ">
            <img
              className="ml-24"
              src="image/profilePatient.jpg"
              alt="patientImage"
            ></img>
            <p className="text-center mt-6">Media heading</p>

            <p className="text-center bg-green-200 text-black mx-16 rounded-lg py-3 text-sm mt-2 ">
              Active
            </p>
          </div>
          <div className=" mt-6 border-gray-4 py-8">
            <p className="pl-10 ">
              Patient Id:
              <span className="text-[#000000] pl-10 text-[14px] opacity-50">#1</span>
            </p>

            <p className="pl-10 pt-4 ">
              Phone :
              <span className="text-[#000000] pl-14 text-[14px] opacity-50">+91854875152</span>
            </p>

            <p className="pl-10 pt-4">
              Age :
              <span className="text-[#000000] pl-20 text-[14px] opacity-50">21 Year</span>
            </p>
            <p className="pl-10 pt-4">
              Blood Group :
              <span className="text-[#000000] pl-4 text-[14px] opacity-50">B+</span>
            </p>
          </div> */}
          <PatientDetails />
        </div>
      </div>
    </div>
  );
}

export default PatientProfile;
