import React from 'react'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import NavBar from '../NavBar/NavBar'

function Header() {


  const location = useLocation()
  const str = useMemo(() => {
    return location.pathname.slice(1)
  }, [location])


  return (
    <div>
      <section className='grid w-[100%] xs:flex grid-cols-12 '>
        <div className='md:hidden'><NavBar /></div>
        <div className='col-span-8'>
          <div className='mt-8 xs:ml-6 ml-20 '><h1 className='text-4xl xs:text-xl font-semibold text-[#2B3B53]'>{str.charAt(0).toUpperCase() + str.slice(1) === "Allprescription" ? 'All Prescription' : location.pathname == "/" ? "Dashboard" : str.charAt(0).toUpperCase() + str.slice(1)}</h1></div>
        </div>

        <div className=' xs:hidden col-span-4'>
          <div className='mt-8 flex'>
            <img src='image/notificationicone.svg'></img>
            <h2 className='ml-16 text-lg text-[#2B3B53] font-medium'>David Morse <br /> <span className='text-sm '>Admin</span></h2>
            <img className='ml-6' src='image/profileimage.jpg' ></img>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Header