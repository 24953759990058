import React from "react";
import './App.css'
import Page from "./Routes/Page";

function App() {
  return (
   <div><Page/></div>
  );
}

export default App;
