export const  patietnData = [
    { id: "#1", Patient_Name: "Rahul", Status: "Pending", phone: "9857463258", Diseas: "Fever", Address: "behind laxmi nagar ujjain mp", },
    { id: "#2", Patient_Name: "Vijay", Status: "Pending", phone: "9857463258", Diseas: "Fever", Address: "behind laxmi nagar ujjain mp", },
    { id: "#3", Patient_Name: "Vikash", Status: "Cancel", phone: "9857463258", Diseas: "Fever", Address: "behind laxmi nagar ujjain mp", },
    { id: "#4", Patient_Name: "Rahul", Status: "Complete", phone: "9857463258", Diseas: "Fever", Address: "behind laxmi nagar ujjain mp", },
    { id: "#5", Patient_Name: "satyam", Status: "Complete", phone: "9857463258", Diseas: "Fever", Address: "behind laxmi nagar ujjain mp", },

  ]

  export const  appointmentData = [
    { id: "#1", Patient_Name: "Rahul", Status: "Pending", phone: "9857463258", Diseas: "Fever", Date: "20/03/2023",  Time:"07:00 am", },
    { id: "#2", Patient_Name: "Vijay", Status: "Pending", phone: "9857463258", Diseas: "Fever", Date: "20/03/2023", Time:"08:00 am" , },
    { id: "#3", Patient_Name: "Vikash", Status: "Cancel", phone: "9857463258", Diseas: "Fever", Date: "20/03/2023", Time:"09:00 am" ,},
    { id: "#4", Patient_Name: "Rahul", Status: "Complete", phone: "9857463258", Diseas: "Fever", Date: "20/03/2023", Time:"02:00 pm", },
    { id: "#5", Patient_Name: "satyam", Status: "Complete", phone: "9857463258", Diseas: "Fever", Date: "20/03/2023", Time:"07:00 pm" ,},

  ]