import React from "react";
import { Link } from "react-router-dom";

function MyDetails() {
  return (
    <div>
      <div className="h-[100px] xs:h-20 border-y mt-4">
        <h2 className="text-[20px] xs:text-[14px] pl-6 pt-4">Persional Info</h2>
        <h4 className="pl-6 pt-1 xs:text-[12px] text-gray-400">Your photo and personal details is here</h4>
      </div>
      <div className="h-[300px] pl-6    pt-4  border-y ">
        <div className="flex ">
          <p className="text-gray-500 xs:text-xs xs:mt-[10px] mt-[14px]">Your Name :</p>
          <input
            className="w-[300px] xs:w-[200px] xs:h-[30px] xs:text-[12px] h-[42px] pl-5 xs:ml-12 ml-40 rounded-md border-gray-200 border "
            type="text"
            name="name"
            value={"David Morse"}
            placeholder="Enter Name "
            disabled
          ></input>
        </div>
        <div className="flex mt-10">
          <p className="text-gray-500 xs:text-xs xs:mt-[10px] mt-[14px]">Your Email :</p>
          <input
            className="w-[300px] xs:w-[200px] xs:h-[30px] xs:text-[12px] h-[42px] pl-5 xs:ml-12 ml-40 rounded-md border-gray-200 border "
            type="text"
            name="name"
            value={"davidmorse@example.com"}
            placeholder="Enter Email "
            disabled
          ></input>
        </div>
        <div className="flex mt-10 ">
          <p className="text-gray-500 xs:text-xs xs:mt-[10px] mt-[14px]">Contact Number :</p>
          <input
            className="w-[300px] xs:w-[200px] xs:h-[30px] xs:text-[12px] h-[42px] pl-5 xs:ml-5 ml-28  rounded-md border-gray-200 border "
            type="text"
            name="name"
            value={"+914585855"}
            placeholder="Enter Number "
            disabled
          ></input>
        </div>

      </div>
    </div>
  );
}

export default MyDetails;
