import React, { useState } from "react";
import Pagination from "react-js-pagination";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Header from "../../shared/components/Header/Header";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    "& th:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "& th:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },

  searchBox: {
    display: "flex",
    alignItems: "center",
    width: 500,
    margin: "auto",
    color: "rgba(0, 0, 0, 0.37)",
    fontWeight: "bold",
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      border: "none",
      borderRadius: "15px",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputAdornment-root": {
      color: "gray",
      marginRight: theme.spacing(1),
    },
  },
}));

const PatientData = [
  {
    id: 1,
    name: "Generic",
    no: '#256',
    purpose: "Fever",
    route: "oral",
    frequency: "Every 8 Hour",
    edit: "image/editicone.svg",
    delete: "image/deleteicone.svg",
  },
  {
    id: 2,
    name: "Pipperacilin",
    no: '#256',
    purpose: "Fever",
    route: "oral",
    frequency: "Every 8 Hour",
    edit: "image/editicone.svg",
    delete: "image/deleteicone.svg",
  },
  {
    id: 3,
    name: "Montec",
    no: '#256',
    purpose: "Fever",
    route: "oral",
    frequency: "Every 8 Hour",
    edit: "image/editicone.svg",
    delete: "image/deleteicone.svg",
  },
  {
    id: 4,
    name: "Azithromycin",
    no: '#256',
    purpose: "Fever",
    route: "oral",
    frequency: "Every 8 Hour",
    edit: "image/editicone.svg",
    delete: "image/deleteicone.svg",
  },
  {
    id: 5,
    name: "Amoxicilin",
    no: '#256',
    purpose: "Fever",
    route: "oral",
    frequency: "Every 8 Hour",
    edit: "image/editicone.svg",
    delete: "image/deleteicone.svg",
  },
  {
    id: 6,
    name: "paracetomol",
    no: '#256',
    purpose: "Fever",
    route: "oral",
    frequency: "Every 8 Hour",
    edit: "image/editicone.svg",
    delete: "image/deleteicone.svg",
  },
];

const columns = [
  { id: 1, label: "Medicine Name", width: 30, align: "center" },
  { id: 2, label: "Purpose", width: 30, align: "center" },
  { id: 3, label: "Route", width: 30, align: "center" },
  { id: 4, label: "Frequency", width: 30, align: "center" },
  { id: 4, label: "Action", width: 30, align: "center" },

];

function createData(name, no, purpose, route, frequency, id, edit, delet,) {
  return { name, no, purpose, route, frequency, id, edit, delet, };
}

const rows = PatientData.map((row) =>
  createData(
    row.name,
    row.no,
    row.purpose,
    row.route,
    row.frequency,
    row.id,
    row.edit,
    row.delete,
  )
);
function AllPrescription() {
  const tableStyle = {
    margin: "16px",
  };
  const tableHeaderStyle = {
    backgroundColor: "#F3F6F9",
    color: "rgba(43, 59, 83, 1)",
  };

  const tableBody = {
    // margin: "50px 0 0 0 ",
  };

  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsOnPage = rows.slice(startIndex, endIndex);

  return (
    <div>

      <div className="mt-20 grid grid-cols-12 w-[100%]">
        <div className=" col-span-8">
          <a
            href="/ManagePrescription"
            className="ml-6 rounded-xl bg-[#209F84] hover:bg-[#1B846D] px-8 py-4 text-white"
          >
            + New Priscription
          </a>
        </div>
        <div className={classes.searchBox}>
          <TextField
            variant="outlined"
            placeholder="search here"
            InputProps={{
              style: {
                color: "rgba(43, 59, 83, 0.63)",
                fontWeight: "bold",
                letterSpacing: "1px",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: "#000" },
            }}
            className={classes.searchInput}
          />
        </div>

        <div className="grid col-span-12 m-8 mt-10">
          <div>
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "10px",
                overflow: "visible",
                padding: "25px",
              }}
            >
              <h2 className="text-[#711685] font-semibold tracking-wider mb-6 text-xl">
                All Priscription
              </h2>
              <Table
                style={tableStyle}
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead component="thead" style={tableHeaderStyle}>
                  <TableRow className={classes.headerRow}>
                    {/* <TableCell align="center">Patient Name</TableCell>
                    <TableCell align="center">Phone</TableCell>
                    <TableCell align="center">Assigned Doctor</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Time</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Action</TableCell> */}

                    {columns.map((column) => (
                      <TableCell
                        className="table_header"
                        key={column.id}
                        align={column.align}
                        sx={{
                          fontSize: "16px",
                          color: "#2B3B53",
                          fontWeight: "bold",
                          border: "none",
                          padding: "25px",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody style={tableBody}>
                  {itemsOnPage.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">
                        <div style={{ display: "row", flexDirection: "column", marginLeftL: "15px" }}>
                          <h2>{row.name}</h2>
                          <h4 className="text-light-100">{row.no}</h4>
                        </div>
                      </TableCell>
                      <TableCell
                        sx={{ color: "rgba(102, 37, 107, 0.54)" }}
                        align="center"
                      >
                        {row.purpose}
                      </TableCell>
                      <TableCell
                        sx={{ color: "rgba(102, 37, 107, 0.54)" }}
                        align="center"
                      >
                        {row.route}
                      </TableCell>
                      <TableCell
                        sx={{ color: "rgba(102, 37, 107, 0.54)" }}
                        align="center"
                      >
                        {row.frequency}
                      </TableCell>
                      <TableCell
                        sx={{ color: "rgba(102, 37, 107, 0.54)" }}
                        align="center"
                      >
                        <div className="flex justify-evenly">
                          <button onClick={() => console.log("edit")}>
                            <img src={row.edit} alt="edit" />
                          </button>
                          <button onClick={() => console.log("delete")}>
                            <img src={row.delet} alt="delete" />
                          </button>
                        </div>
                      </TableCell>


                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={rows.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                // style={{display:"flex",jusitfyContent:'end'}}
                innerClass="pagination"
                itemClass="pagination-items"
                hideFirstLastPages={true}
                nextPageText=">>"
                prevPageText="<<"
              />
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllPrescription;
