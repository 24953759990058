import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ManagePrescription() {
  const [PrescriptionData, SetPrescriptionData] = useState({
    medicine_Name: "",
    purpose: "",
    dosage: "",
    route: "",
    frequancy: "",
    notes: "",
  });

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    SetPrescriptionData({ ...PrescriptionData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // alert(PrescriptionData.medicine_Name);
    console.log(PrescriptionData);
    try {
      // const response = await axios.post('/api/endpoint', formData);
      if (PrescriptionData) {
        toast.success('Medicine Add successfully!');
      } else {
        toast.error('Something went wrong. Please try again later.');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="">
      <prescription className=" p-5 pt-7 " >
        <form onSubmit={handleSubmit}>
          <div className="m-7 xs:m-0 mt-10 mb-18 bg-white rounded-lg">
            <div className="flex justify-between p-6 pb-0 pl-8  ">
              <div className="">
                <h2 className=" text-gray-500  leading-6 font-medium text-xl ">
                  Manage Prescription
                </h2>
              </div>
            </div>
            <div className=" mx-40 xs:grid-cols-1 mt-10 ml-8 grid grid-cols-2">
              <div className="col-span-1">
                <h4 className="text-inputText-500 font-medium">
                  Medicine Name
                </h4>
                <input
                  type="text"
                  name="medicine_Name"
                  onChange={handleInputs}
                  value={PrescriptionData.medicine_Name}
                  className="bg-input-500 px-4 mt-4 rounded-xl  border-inputBorder-500 xs:w-[300px] border-2 h-12 w-[400px]"
                  placeholder=" Enter Medicine Name"
                ></input>
              </div>
              <div className=" xs:mt-4  col-span-1">
                <h4 className="text-inputText-500 font-medium">Purpose</h4>
                <input
                  type="text"
                  name="purpose"
                  onChange={handleInputs}
                  value={PrescriptionData.purpose}
                  className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 xs:w-[300px] border-2 h-12 w-[400px]"
                  placeholder="Enter Purpose"
                ></input>
              </div>
            </div>
            <div className="xs:mt-3  mx-40 mt-7 xs:grid-cols-1 ml-8 grid grid-cols-2">
              <div className=" col-span-1">
                <h4 className="text-inputText-500 font-medium">Dosage</h4>
                <select
                  type="Select"
                  name="dosage"
                  onChange={handleInputs}
                  value={PrescriptionData.dosage}
                  className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 xs:w-[300px] border-2 h-12 w-[400px]"
                  required
                  placeholder="Select "
                >
                  <option value="select">Select</option>
                  <option className=" " value="Morning">
                    Morning
                  </option>
                  <option value="Day">Day</option>
                  <option value="Night">Night</option>
                  <option value="    Morning , Day , Nigh">
                    Morning , Day , Night
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className=" xs:mt-4  col-span-1">
                <h4 className="text-inputText-500 font-medium">Route</h4>
                <select
                  type="select"
                  name="route"
                  onChange={handleInputs}
                  value={PrescriptionData.route}
                  className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 xs:w-[300px] border-2 h-12 w-[400px]"
                  required
                  placeholder="Select "
                >
                  <option value="select">Select</option>
                  <option className=" " value="injuction">
                    injuction
                  </option>
                  <option value="oral">oral</option>
                  <option value="drop">drop</option>
                  <option value="other">other</option>
                </select>
              </div>
            </div>
            <div className="xs:mt-3  mx-40 mt-7 xs:grid-cols-1 ml-8 grid grid-cols-2">
              <div className="col-span-1">
                <h4 className="text-inputText-500 font-medium">Frequancy</h4>
                <select
                  type="select"
                  name="frequancy"
                  onChange={handleInputs}
                  value={PrescriptionData.frequancy}
                  className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 xs:w-[300px] border-2 h-12 w-[400px]"
                  required
                  placeholder="Select "
                >
                  <option value="select">Select</option>
                  <option className=" " value="Every 4 hour">
                    Every 4 hour
                  </option>
                  <option value=" Every 8 hour">Every 8 hour</option>
                  <option value="once a day">once a day</option>
                  <option value="other">other</option>
                </select>
              </div>
              <div className="xs:mt-4  col-span-1">
                <h4 className="text-inputText-500 font-medium">Notes</h4>
                <input
                  type="text"
                  name="notes"
                  onChange={handleInputs}
                  value={PrescriptionData.notes}
                  className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 xs:w-[300px] border-2 h-12 w-[400px]"

                  required
                  placeholder="Enter Notes "
                ></input>
              </div>
            </div>

            {/* Save/Cancel */}
            <div className="flex m-4 mt-10 mx-8 mb-16 flex-wrap">
              <button
                type="submit"
                className="flex m-2 cursor-pointer h-10 w-28 justify-between px-5 rounded-lg font-medium text-sm bg-[#209F84] text-white"
              >
                <span className="self-center m-auto">Submit</span>
              </button>

              <Link to="/ManagePrescription">
                <div className="flex m-2 cursor-pointer h-10 w-28 hover:border-red-500 justify-between px-5 rounded-lg font-medium text-sm border-2 border-[#209F84]">
                  <span className="self-center m-auto text-[#209F84]">
                    Cancel
                  </span>
                </div>
              </Link>
            </div>
            <ToastContainer />
          </div>
        </form>
      </prescription>
    </div>
  );
}

export default ManagePrescription;
