import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { menuOptions } from '../SidebarPage/Sidebar'

function NavBar() {
    const [nav, setNav] = useState(false)
    return (
        <div> <div className='mt-8 ml-8' onClick={() => setNav(!nav)}>
            <img src="image/toggle.svg"></img>
        </div>
            <div className={nav ? 'fixed top-0 left-0 w-[320px] h-screen bg-white z-10 duration-700' : 'fixed top-0 left-[-100%] w-[320px] h-screen bg-white z-10 duration-700'}>

                <div onClick={() => setNav(!nav)}>
                    <img className="absolute mt-8 right-6 h-6 w-6 cursor-pointer " src='Image/crosss.svg'></img>
                </div>
                <div className=" md:hidden mt-8 bg-white pl-6  col-span-2">
                    <div className="flex justify-between">
                        {" "}
                        <h1 className="text-2xl  font-bold text-dark-500">5TechG Lab</h1>{" "}
                        {/* <img className="px-4" src="image/toggle.svg"></img> */}
                    </div>
                    <div className="mt-16">
                        {menuOptions.map((item, index) => (
                            <NavLink
                                className="  flex hover:bg-[#209F84] hover:text-white hover:rounded-2xl px-4 my-2 py-4 text-light-100 mr-4 mt-4"
                                id="link"
                                to={item.listUrl}
                                key={index}
                            >
                                <img className="pr-6" src={item.image} onClick={() => setNav(!nav)}></img>
                                <h1 className="text-lg  font-semibold" onClick={() => setNav(!nav)}>{item.listName}</h1>
                            </NavLink>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar