import React from "react";
import { Link } from "react-router-dom";

function ClinicDetails() {
  return (
    <div>
    <div className="h-[100px] xs:h-20 border-y mt-4">
        <h2 className="text-[20px] xs:text-[14px] pl-6 pt-4">Clinic Info</h2>
        <h4 className="pl-6 pt-1 xs:text-[12px] text-gray-400">Your clinic log and clinic details is here</h4>
      </div>
      <div className="h-[350px] pl-6    pt-4  border-y mt-">
        <div className=" flex">
        <p className="text-gray-500 xs:text-xs xs:mt-[10px] mt-[14px]">Clinic Logo :</p>
          <input
            class=" w-[300px] xs:w-[200px] xs:h-[30px] xs:text-[12px] xs:ml-10 h-[42px] xs:mt-0  mt-4 ml-40  rounded-md  m-0 block  cursor-pointer  border border-solid border-neutral-300 bg-white bg-clip-padding px-3 xs:py-0.5 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 xs: file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-[#f5f5f5] file:px-3 file:py-2 file:text-gray-600 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-neutral-200 focus:border-primary focus:bg-white focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
            type="file"
            name="file"
            id="formFile"
          />
        </div>
        <div className="flex mt-10 ">
          <p className="text-gray-500 xs:text-xs xs:mt-[10px] mt-[14px]">Clinic Name :</p>
          <input
            className="w-[300px] xs:w-[200px] xs:h-[30px] xs:text-[12px] h-[42px] pl-5 xs:ml-10 ml-[150px] rounded-md border-gray-200 border "
            type="text"
            name="name"
            value={"My Clinic"}
            placeholder="Enter Name "
            disabled
          ></input>
        </div>
        <div className="flex mt-10">
          <p className="text-gray-500 xs:text-xs xs:mt-[10px] mt-[14px]">Clinic Address :</p>
          <input
            className="w-[300px] xs:w-[200px] xs:h-[30px] xs:text-[12px] h-[42px] pl-5 xs:ml-8 ml-[132px] rounded-md border-gray-200 border "
            type="text"
            name="name"
            value={"Behind singh tower new delhi"}
            placeholder="Enter Address "
            disabled
          ></input>
        </div>
        <div className="flex mt-10 ">
          <p className="text-gray-500 xs:text-xs xs:mt-[10px] mt-[14px]">Contact Number :</p>
          <input
            className="w-[300px] xs:w-[200px] xs:h-[30px] xs:text-[12px] h-[42px] pl-5 xs:ml-5 ml-28  rounded-md border-gray-200 border "
            type="text"
            name="name"
            value={"+914585855"}
            placeholder="Enter Number "
            disabled
          ></input>
        </div>

      </div>
    </div>
  );
}

export default ClinicDetails;
