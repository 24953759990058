import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Header from "../../shared/components/Header/Header";
import PrescriptionDetails from "./PrescriptionDetails";
import PrescriptionTemplate from "./PrescriptionTemplate";

const useStyles = makeStyles((theme) => ({
  searchBox: {
    display: "flex",
    alignItems: "center",
    // width: 250,
    margin: "auto",
    "& .MuiInputBase-root": {
      backgroundColor: "#F5F5F5",
      border: "1px solid rgba(42, 45, 84, 0.15)",
      borderRadius: "15px",
      marginTop: "10px",
      "&:hover": {
        backgroundColor: "#F5F5F5",
        border: "1px solid rgba(42, 45, 84, 0.15)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputAdornment-root": {
      color: "gray",
      marginRight: theme.spacing(1),
    },
  },
}));



const dummyPrescription = [
  {
    no: 4,
    date: "31/12/2022",
    nextvisit: "12/01/2023",
    name: "Rahul Singh",
    phone: 885688785,
    gender: "male",
    dob: "07/07/2001",
    address: "xyz colony , India",
    weight: "30kg",
    age: " 3 year",
  },
];

function Prescription() {



  const classes = useStyles();


  const [PatientDetail, SetPatientDetail] = useState({ patientName: "", gender: "", age: "", address: " " })
  const [PrescriptionDetail, SetPrescriptionDetail] = useState({ MedicineName: "", quantity: "", dosage: "", notes: "" })


  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    SetPatientDetail({ ...PatientDetail, [name]: value });
    SetPrescriptionDetail({ ...PrescriptionDetail, [name]: value });

  };
  const handleClick = (event) => {
    event.preventDefault();

    alert(PrescriptionDetail.MedicineName);
    console.log(PrescriptionDetail);


  };

  //funcrion for adding prescription on button



  const [selectedOption, setSelectedOption] = useState("Select Dosage");

  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }

    //funcrion for adding prescription on button
    const [numForms, setNumForms] = useState(1);
    function handleAddForm() {
      setNumForms(numForms + 1);
    }
    function handleDeleteForm(index) {
      setNumForms(numForms - 1);
    }
    
    const forms = [];
  for (let i = 0; i < numForms; i++) {
    forms.push(
      <PrescriptionDetails key={i} index={i} showDeleteButton={numForms > 1} onDeleteForm={handleDeleteForm} />
    );
  }

  
  return (
    <div>

      <div className="mt-20 xs:mt-10 grid grid-cols-12 w-[100%]">
        <div className="col-span-11 w-full m-auto ml-8 xs:ml-0 grid ">
          <div className="flex xs:flex-col justify-between">
            <div className=" ">
              <h2 className="text-[#2B3B53] font-semibold tracking-wide mt-2 xs:ml-4 text-xl">
                Add New Priscription
              </h2>
            </div>
            <div className="flex xs:flex-col  text-[#FFFFFF]">
              <a href="/ManagePrescription" className="bg-[#9F2020] w-fit py-4 px-8 rounded-2xl mr-6 hover:bg-[#DF2E38] xs:my-4 xs:ml-4">
                Manage Priscription
              </a>
              <a href="/AddPatient" className="bg-[#209F84] hover:bg-[#1B846D] w-fit py-4 px-8 xs:px-14 rounded-2xl xs:ml-4">
                +New Patient
              </a>
            </div>
          </div>
          <div className="bg-white w-full mt-6 grid">
            <div className="grid  w-full border-2 border-[#0000001F] px-10 pb-6 ">
              <div className="mt-6">
                <h1 className="text-[#209F84] text-lg">Patient Details</h1>
              </div>
              <div className="flex  flex-wrap mt-6 justify-between ">
                <div className="grid xs:flex-none ">
                  <h4 className="text-light-100">Patient Name</h4>
                  <div className={classes.searchBox}>
                    <TextField
                    size="small"
                      variant="outlined"
                      value={PatientDetail.patientName}
                      name="patientName"
                      onChange={handleInputs}
                      type="text"
                      placeholder="Search Patient..."
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: { color: "#000" },
                      }}
                      className={classes.searchInput}
                    />
                  </div>
                </div>

                <div className="grid ">
                  <h4 className="text-light-100">Gender</h4>
                  <div className={classes.searchBox}>
                    <TextField
                      onChange={handleInputs}

                      value={PatientDetail.gender}
                      name="gender"
                      type="text"
                      variant="outlined"
                      className={classes.searchInput}
                    />
                  </div>
                </div>

                <div className="grid ">
                  <h4 className="text-light-100">Age</h4>
                  <div className={classes.searchBox}>
                    <TextField
                      value={PatientDetail.age}
                      onChange={handleInputs}
                      name="age"
                      type="text"
                      variant="outlined"
                      className={classes.searchInput}
                    />
                  </div>
                </div>

                <div className="grid ">
                  <h4 className="text-light-100">Address</h4>
                  <div className={classes.searchBox}>
                    <TextField
                      value={PatientDetail.address}
                      onChange={handleInputs}
                      name="address"
                      type="text"
                      variant="outlined"
                      className={classes.searchInput}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid w-full border-2 border-[#0000001F] px-10 mt-6 pb-6">
              <div className="mt-6 flex justify-between">
                <div>
                  <h1 className="text-[#209F84] text-lg">
                    Prescription Details
                  </h1>
                </div>
                <div className="flex">
                  {/* <img src="image/plus.svg" alt="adddNote" /> */}
                  <button onClick={handleAddForm} className="bg-[#209F84] hover:bg-[#1B846D] rounded-full text-white px-3 text-xl font-black  ml-6">
                    +
                  </button>
                  <button onClick={handleClick} className="bg-[#209F84] hover:bg-[#1B846D] rounded-lg text-white px-6 py-2 ml-6">
                    Save
                  </button>
                </div>
              </div>
              {forms}
            </div>
            <PrescriptionTemplate />
     
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prescription;
