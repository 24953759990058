import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function AppointmentDetails() {

    const [AppointmentDetail, setAppointmentDetails] = useState({ appointment_time: "", date: "", disease: "", notes: "" })

    let name, value;
    const handleInputs = (e) => {
        name = e.target.name;
        value = e.target.value;
        setAppointmentDetails({ ...AppointmentDetail, [name]: value });
    };

    return (
        <div className="">
            <appointments className=" ">
                <div className=" mt-10  mb-18 bg-white rounded-lg">
                    <div className="flex justify-between p-6 pb-0 pl-8  ">
                        <h2 className=" text-purple-600 leading-6 font-medium text-xl ">
                            Appointment Details
                        </h2>
                    </div>
                    <div className=" mx-40 mt-4 gap-x-40 ml-8 xs:grid-cols-1 grid grid-cols-3">
                        <div className=" mt-4  col-span-1">
                            <h4 className="text-inputText-500 font-medium">
                                Date of Appointment
                            </h4>
                            <input
                                type="date"
                                name="date"
                                id="date"
                                value={AppointmentDetail.date}
                                onChange={handleInputs}
                                className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2 xs:w-[280px] h-12 w-[300px]"
                                placeholder="mm/dd/yy"
                            ></input>
                        </div>
                        <div className=" mt-4   col-span-1">
                            <h4 className="text-inputText-500 font-medium">
                                Time of Appointment
                            </h4>
                            <input
                                className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 xs:w-[280px]  w-[300px]"
                                type="time"
                                value={AppointmentDetail.appointment_time}
                                onChange={handleInputs}
                                name="appointment_time"
                                placeholder="Enter Time"

                            ></input>
                        </div>
                        <div className=" mt-4   col-span-1">
                            <h4 className="text-inputText-500 font-medium">
                                Disease
                            </h4>
                            <input
                                className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 xs:w-[280px] w-[300px]"
                                type="text"
                                value={AppointmentDetail.disease}
                                onChange={handleInputs}
                                name="disease"
                                placeholder="Enter Disease"
                            ></input>
                        </div>

                        <div className=" mt-4   col-span-1">
                            <h4 className="text-inputText-500 font-medium">
                                Notes
                            </h4>
                            <input
                                className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2 h-12 xs:w-[280px]   w-[300px]"
                                type="text"
                                value={AppointmentDetail.notes}
                                onChange={handleInputs}
                                name="notes"
                                placeholder="Notes"
                            ></input>
                        </div>
                    </div>

                </div>
            </appointments>
        </div>
    );
}

export default AppointmentDetails;
