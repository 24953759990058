
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  searchBox: {
    display: "flex",
    alignItems: "center",
    // width: 250,
    margin: "auto",
    "& .MuiInputBase-root": {
      backgroundColor: "#F5F5F5",
      border: "1px solid rgba(42, 45, 84, 0.15)",
      borderRadius: "15px",
      marginTop: "10px",
      "&:hover": {
        backgroundColor: "#F5F5F5",
        border: "1px solid rgba(42, 45, 84, 0.15)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputAdornment-root": {
      color: "gray",
      marginRight: theme.spacing(1),
    },
  },
}));

const PrescriptionDetails = ({ index, showDeleteButton, onDeleteForm }) => {
  const classes = useStyles();

  // function to for dropdown
  const [selectedOption, setSelectedOption] = useState("Select Dosage");
  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }

  function handleDeleteClick() {
    onDeleteForm(index);
  }

  const [value , setValue] = useState("")

  

  return (
    <div>
      <div className="flex mt-6 justify-between">
        <div className="grid ">
          <h4 className="text-light-100">Medicine Name</h4>
          <div className={classes.searchBox}>
            {/* <TextField
              placeholder="Search Medicine.."
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: "#000" },
              }}
              className={classes.searchInput}
            /> */}
            <div className="flex border border-gray-600 rounded-lg w-[250px]">
              <input
              value = {value}
                type="text"
                className=" py-4 pl-4 outline-none rounded-xl  "
                placeholder="Search by name or Id.."
              ></input>
              <button className=""> <img className="pr-2 " src="image/akar-icons_search.svg"></img> </button>
            </div>
          </div>

        </div>

        <div className="grid ">
          <h4 className="text-light-100">Quantity</h4>
          <div className={classes.searchBox}>
            <TextField variant="outlined" className={classes.searchInput} />
          </div>
        </div>

        <div className="grid ">
          <h4 className="text-light-100">Dosage</h4>

          <div
            style={{
              width: "220px",
              border: "1px solid rgba(42, 45, 84, 0.15)",
              height: "55px",
              marginTop: "20px",
              borderRadius: "15px",
            }}
          >
            <select
              className="w-full h-full bg-[#F5F5F5] rounded-2xl text-light-50"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <option value="option1">Morning</option>
              <option value="option2">Day</option>
              <option value="option3">Night</option>
              <option value="option3">Morning,Night</option>
              <option value="option3">Morning,Day,Night</option>
            </select>
          </div>
        </div>

        <div className="grid ">
          <h4 className="text-light-100">Notes</h4>
          <div className={classes.searchBox}>
            <TextField
              placeholder="Add Notes"
              variant="outlined"
              className={classes.searchInput}
            />
          </div>
        </div>
        <div className="flex items-end">
          {showDeleteButton && (
            <IconButton onClick={handleDeleteClick} color='error' aria-label="delete" size="large">
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionDetails;