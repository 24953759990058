import MaterialTable from 'material-table'
import React ,{useState} from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import { render } from '@testing-library/react';

function AppointmentTable() {
    const defaultMaterialTheme = createTheme();

    const columns = [
        { title: " appointmentDate",  field: "appointmentDate",  },
        { title: "appointmentTime", field: "time", render:(rowData)=><div className='text-[#2781D5]'>{rowData.time }</div> },
        { title: "BookingDate", field: "BookingDate",  },
     
        { title: "Diseas", field: "Diseas"},
        { title: "Status", field: "Status", render:(rowData)=> <div className='rounded-md w-32 text-sm text-center py-1 text-white' style={{ backgroundColor: rowData.Status == "Cancel" ? "#FFDDE4" : rowData.Status == "Pending" ? "#FFF6E0" : "#C9F7F5" , color: rowData.Status == "Cancel" ? "red" : rowData.Status == "Pending" ? "orange" : "#1BC5BD" }} >{rowData.Status}</div>  },
      ]

      const [tableData, setTableData] = useState([
        { appointmentDate: "20/01/2023", time: "7:00 pm", Status: "Pending", BookingDate: "20/01/2024", Diseas: "Fever", },
        { appointmentDate: "20/01/2023", time: "7:00 pm", Status: "Pending", BookingDate: "20/01/2024", Diseas: "Fever", },
        { appointmentDate: "20/01/2023", time: "7:00 pm", Status: "Cancel", BookingDate: "20/01/2024", Diseas: "Fever", },
        { appointmentDate: "20/01/2023", time: "7:00 pm", Status: "Complete", BookingDate: "20/01/202", Diseas: "Fever", },
        { appointmentDate: "20/01/2023", time: "7:00 pm", Status: "Complete", BookingDate: "20/01/2023", Diseas: "Fever", },
  
      ])

    return (
        <div><ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable columns={columns} data={tableData} title = "All Appointments" options={{exportButton: true , headerStyle: { background: "#F3F6F9",color:"#2B3B53" , fontSize:"16px"}  }}
           />
        </ThemeProvider></div>
    )
}

export default AppointmentTable