import { React, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js/auto";

function PatientStatus_Chart() {
  const [userData, setuserData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "",
        data: [220, 300, 230, 250, 600, 548, 200, 300, 210, 230, 860, 1000],
        backgroundColor: ["#209F84"],
        borderRadius: 22,
        borderWidth: 15,
        borderColor: "rgb(251,251,251,0)",
      },
    ],
  });

  const [chartOptions, setChartOptions] = useState({
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },

        ticks: {
          color: "#2B3B53",
          font: {
            size: 14,
            family: "Poppins",
            weight: 500,
          },
        },
      },

      y: {
        // max:1000,
        // min:0,

        ticks: {
          stepSize: 200,
          color: "#2B3B53",
          font: {
            size: 13,
            family: "Poppins",
            weight: 600,
          },
        },
      },
    },
  });

  return (
    <>
      <div className="m-12 bg-white  rounded-lg">
        <div className="flex justify-between p-8 pl-14 pb-0 ">
          <div className="">
            <h2 className="leading-6 font-semibold text-pulse-gray text-xl ">
              Patients Status
            </h2>
            <h1 className="leading-8 font-semibold text-pulse-gray/50 text-sm ">
              Total Patients on{" "}
              <span className=" text-pulse-chart-yellow">2021</span>
            </h1>
            <h2 className="leading-6 font-semibold text-purple-500 text-xl ">
              70,210 People
            </h2>
          </div>

          <div className="w-30 text-white h-12 rounded-lg px-4 bg-gray-400 mr-14">
            <select
              name="year"
              id="year"
              className="w-24 p-2 h-12 bg-gray-400 text-lg rounded font-medium outline-none">
              <option value="2020">2020</option>
              <option value="2021" selected>
                2021
              </option>
              <option value="2022">2022</option>
            </select>
          </div>
        </div>

        <div style={{ width: "55rem" }} className="py-12 self-center m-auto">
          <Bar data={userData} options={chartOptions} />
        </div>
      </div>
    </>
  );
}

export default PatientStatus_Chart;
