import "./style.css";
import MaterialTable, { MTableToolbar } from 'material-table'
import React, { useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import { render } from '@testing-library/react';
import { Avatar, Grid } from '@material-ui/core';
import { appointmentData } from "../constantData/Constant";
import { patietnData } from "../constantData/Constant";

function Appointment() {

  const [saveAppointment, SetSaveAppointment] = useState({ Patient_Name: "", date: "", time: "", notes: "" })

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    SetSaveAppointment({ ...saveAppointment, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    alert(saveAppointment.Patient_Name);
    console.log(saveAppointment);
  };

  const defaultMaterialTheme = createTheme();

  const columns = [
    { title: "Id", field: "id", editable: false, render: (rowData) => <span className='text-[#2781D5]'>{rowData.id}</span> },
    {
      title: "Patient Name", field: "Patient_Name", render: (row) => <Grid container alignItems="center">
        <Grid item sm={3}>
          <Avatar style={{ backgroundColor: "#209F84", }}>{row.Patient_Name[0]}</Avatar>
        </Grid>
        <Grid item>
          <span className="ml-4"> {row.Patient_Name}</span>
        </Grid>
      </Grid>
    },
    { title: "Phone", field: "phone", },
    { title: "Date", field: "Date", searchable: false },
    { title: "Time", field: "Time", searchable: false, render: (rowData) => <span className='text-[#2781D5]'>{rowData.Time}</span> },
    { title: "Diseas", field: "Diseas" },
    { title: "Status", field: "Status", render: (rowData) => <div className='rounded-md w-32 text-sm text-center py-1 text-white' style={{ backgroundColor: rowData.Status == "Cancel" ? "#FFDDE4" : rowData.Status == "Pending" ? "#FFF6E0" : "#C9F7F5", color: rowData.Status == "Cancel" ? "red" : rowData.Status == "Pending" ? "orange" : "#1BC5BD" }} >{rowData.Status}</div> },
  ]



  const [tableData, setTableData] = useState(appointmentData)

  const [searchValue, setSearchValue] = useState("")

  const onChange = (event) => {

    setSearchValue(event.target.value)

  }

  const onSearch = (searchTerm) => {
    setSearchValue(searchTerm)
    console.log("search", searchTerm)
  }

  return (
    <div>
      <div className="  w-[100%] ">

        <div className="mt-8 xs:ml-6 flex xs:flex-col ">
          <form className="md:flex md:ml-4" onSubmit={handleSubmit}>
            <div className="flex border border-gray-600 rounded-lg xs:w-[300px] w-[250px]">
              <input
                onChange={onChange}
                name="Patient_Name"
                value={searchValue}
                type="text"
                className=" py-2 pl-4 outline-none rounded-xl  "
                placeholder="Enter Patient name or Id.."
              ></input>
              <button onClick={() => onSearch(searchValue)} className="xs:pl-8"> <img className="pr-2  xs:h-5 " src="image/akar-icons_search.svg"></img> </button>
            </div>
            <input
              className="w-[180px] xs:w-[300px] xs:ml-0 xs:mt-2 h-[46px] pr-4 pl-5  ml-4 rounded-xl shadow-md "
              type="date"
              name="date"
              id="date"
              value={saveAppointment.date}
              onChange={handleInputs}
              required
            ></input>
            <input
              className="w-[180px] h-[46px] xs:w-[300px] xs:ml-0 xs:mt-2 pl-5 pr-3 ml-6 rounded-xl shadow-md "
              type="time"
              name="time"
              value={saveAppointment.time}
              onChange={handleInputs}
              placeholder="Appointment Time "
              required
            ></input>
            <input
              className="w-[180px] xs:w-[300px] xs:ml-0 xs:mt-2 h-[46px] pl-5 ml-6 rounded-xl shadow-md "
              name="notes"
              value={saveAppointment.notes}
              onChange={handleInputs}
              placeholder="Notes "
              required
            ></input>
            <button
              type="submit"
              className=" xs:rounded-md xs:ml-0 ml-4 xs:mt-3 rounded-xl bg-[#209F84] hover:bg-[#1B846D] xs:py-[6px] xs:text-sm  py-2 px-4 text-white"
            >
              Save
            </button>
          </form>
          <span className="md:ml-[14px] xs:ml-[100px] xs:-mt-6  mt-2">or</span>
          <a href="/AddPatient">
            <button

              className="ml-6 xs:ml-[150px] xs:-mt-[31px] xs:rounded-md xs:w-[150px] xs:text-xs rounded-xl bg-[#9f2820] hover:bg-[#1B846D]  py-2 px-4 text-white"
            >
              + Add New Patient
            </button></a>
        </div>
        {patietnData.filter((item) => {
          const searchTerm = searchValue.toLowerCase();
          const Patient_Name = item.Patient_Name.toLowerCase()
          return searchTerm && Patient_Name.startsWith(searchTerm) && Patient_Name != searchTerm
        }).map((item) => {
          return <div className="mt-2  cursor-pointer ml-4">
            <h1 onClick={() => onSearch(item.Patient_Name)} >{item.Patient_Name}</h1>
          </div>
        })}
        <div className="md:grid  md:col-span-12 xs:m-1 xs:mt-8 md:m-8 mt-4">
          <div>
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable columns={columns} data={tableData} title="All Patient"

                //////// edit and delete icone

                editable={{
                  onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                    const updatedData = [...tableData]
                    updatedData[oldRow.tableData.id] = newRow
                    setTableData(updatedData)
                    setTimeout(() => resolve(), 500)
                  }),
                  onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                    const updatedData = [...tableData]
                    updatedData.splice(selectedRow.tableData.id, 1)
                    setTableData(updatedData)
                    setTimeout(() => resolve(), 1000)
                  })
                }}
                actions={
                  [
                    {
                      icon: () => { <div><button>click me</button></div> },
                      isFreeAction: true
                    }

                  ]
                }
                options={{ exportButton: true, headerStyle: { background: "#F3F6F9", color: "#2B3B53", fontSize: "16px", }, actionsColumnIndex: -1, }}
                icons={{ Delete: () => <img src='image/deleteicone.svg'></img>, Edit: () => <img src='image/editicone.svg'></img>, }}
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Appointment;
