import React from "react";
import { useState } from "react";
import Header from "../../shared/components/Header/Header";
import MyDetails from "./components/MyDetails";
import Plan from "./components/Plan";
import Notification from "./components/Notification";
import ClinicDetails from "./components/ClinicDetails";
import "./DoctoreProfile.css";
import DoctoreProfile from "./components/DoctoreProfile";

function Setting() {
  // tab functionality
  const [tabState, setTabState] = useState(0);
  const [tabComp, setTabComp] = useState(0);

  const [tabComponents, setTabComponents] = useState([
    [<MyDetails setTabComp={setTabComp} />],
    [<ClinicDetails setTabComp={setTabComp} />],
    [<Notification setTabComp={setTabComp} />],
    [<Plan setTabComp={setTabComp} />],
  ]);

  return (
    <div>

      <div className="grid xs:grid-cols-1 grid-cols-4 mt-10">
     <div className="md:hidden flex bg-white h-[200px]"> <DoctoreProfile/></div>
        <div className="col-span-3 h-[600px] bg-white mr-6 xs:mr-0 xs:ml-0 ml-6 ">
          <div>
            <ul className="flex gap-[120px] xs:gap-6 shadow-sm  border-b py-4 pl-8 xs:mt-8 mt-0">
              <li className={"hover:text-[#711685] xs:text-xs cursor-pointer " + (tabState === 0 ? "tabs" : "")}
                onClick={() => {
                  setTabState(0);
                  setTabComp(0);
                }}
              >
                My Details{" "}
              </li>
              <li className={"hover:text-[#711685] xs:text-xs cursor-pointer " + (tabState === 1 ? "tabs" : "")}
                onClick={() => {
                  setTabState(1);
                  setTabComp(0);
                }}
              >
                {" "}
                Clinic Details{" "}
              </li>
              <li className={"hover:text-[#711685] xs:text-xs cursor-pointer " + (tabState === 2 ? "tabs" : "")}
                onClick={() => {
                  setTabState(2);
                  setTabComp(0);
                }}
              >
                {" "}
                Notification{" "}
              </li>
              <li className={"hover:text-[#711685] xs:text-xs cursor-pointer " + (tabState === 3 ? "tabs" : "")}
                onClick={() => {
                  setTabState(3);
                  setTabComp(0);
                }}
              >
                {" "}
                Plan{" "}
              </li>
            </ul>
          </div>
          {tabComponents[tabState][tabComp]}
          {/* <div id="hi" onClick={setTabComponents}></div> */}
        </div>
        <div className="col-span-1 xs:hidden  mr-4 h-[600px] bg-white">
          <DoctoreProfile />
        </div>
      </div>
    </div>
  );
}

export default Setting;

