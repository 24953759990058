import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment,IconButton } from "@material-ui/core";
import {
  MailOutline,
  LockOpenOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    background: `url('/envelope-icon.svg') no-repeat left center`,
    backgroundSize: "20px 20px",
    // border: "1px solid rgba(181, 26, 138, 0.34)",
    // borderRadius: "8px",
  },
  icon: {
    color: "rgba(8, 33, 97, 0.56)",
  },

  textField: {
    "& .MuiOutlinedInput-root": {
      //   boxShadow: "-1px 12px 15px -1px rgba(0,0,0,0.36)",
      "& fieldset": {
        border: "1px solid rgba(181, 26, 138, 0.34)",
        borderRadius: "10px",
      },
      "&:hover fieldset": {
        border: "1px solid rgba(181, 26, 138, 0.34)",
        borderRadius: "10px",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(181, 26, 138, 0.34)",
        borderRadius: "10px",
      },
    },
  },
}));

const Login = () => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="w-screen h-screen flex flex-wrap justify-evenly items-center">
        <div className="w-4/5 h-4/5 m-auto flex flex-wrap justify-center p-8 items-center">
          <div className="w-96 h-96 mr-8" >
            <img className="rounded-3xl" src="image/hospital.png" alt="login" />
          </div>
          <div className=" grid p-8 w-2/5">
            <div className="flex justify-center">
              <h2 className="text-green-500 font-semibold text-xl tracking-wider">
                Log In To Your Account
              </h2>
            </div>
            <div className="grid mt-8 ">
              <h4 className="text-light-100 my-2">Email</h4>
              <TextField
                variant="outlined"
                notched
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutline className={classes.icon} />
                    </InputAdornment>
                  ),
                  classes: { input: classes.input },
                }}
              />

              <h4 className="text-light-100 my-2">Password</h4>
              <TextField
                variant="outlined"
                type={showPassword ? "text" : "password"}
                notched
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOpenOutlined className={classes.icon} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: { input: classes.input },
                }}
              />

              <button className="bg-green-500 w-full font-medium mt-8 mb-2 p-4 text-white rounded-xl">
                Log In
              </button>
              <a href="/forgot" className="ml-auto text-dark-500">
                Forget Password ?
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
