import React from 'react'

function PatientDetails() {
    return (

        <div className=" ">
            <div className="mt-4 xs:gap-4 xs:flex ">
                <img
                    className="ml-24 xs:h-16 xs:ml-6"
                    src="image/profilePatient.jpg"
                    alt="patientImage"
                ></img>
               <div> <p className="text-center xs:text-sm xs:mt-1  mt-6">Media heading</p>
                <p className="text-center  bg-green-200 text-black mx-16 xs:mx-0 rounded-lg py-3 xs:py-1.5 text-sm mt-2 ">
              Active
            </p></div>
            </div>
          
            <div className=" pl-10 xs:pl-6  xs:mt-2 mt-6 border-gray-4 py-8">
            <p className="xs:text-xs">
                    Patient Id :
                    <span className="text-[#000000] pl-6 text-[14px] xs:text-xs opacity-50">#12</span>
                </p>
                <p className="xs:text-xs pt-4">
                    Phone :
                    <span className="text-[#000000] pl-6 xs:pl-10 text-[14px] xs:text-xs opacity-50">+91854875152</span>
                </p>

                <p className=" xs:text-xs pt-4">
                    Age :
                    <span className="text-[#000000] pl-12 xs:pl-14  text-[14px] xs:text-xs opacity-50">21 Year</span>
                </p>
                <p className=" xs:text-xs pt-4">
                Blood Group :
                    <span className="text-[#000000] pl-8 xs:pl-2 text-[14px] xs:text-xs opacity-50">B +</span>
                </p>
            </div>
        </div>
    )
}




export default PatientDetails