import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import { render } from '@testing-library/react';

function PrescriptionProfile() {
  const defaultMaterialTheme = createTheme();

  const columns = [
    { title: " appointmentDate", field: "appointmentDate", },
    { title: "appointmentTime", field: "time", render: (rowData) => <div className='text-[#2781D5]'>{rowData.time}</div> },
    { title: "PrescriptionName", field: "PrescriptionName", },

    { title: "Diseas", field: "Diseas" },
  ]

  const [tableData, setTableData] = useState([
    { appointmentDate: "20/01/2023", time: "7:00 pm", PrescriptionName: "Paracetamol", Diseas: "Fever", },
    { appointmentDate: "20/01/2023", time: "7:00 pm", PrescriptionName: "Paracetamol", Diseas: "Fever", },
    { appointmentDate: "20/01/2023", time: "7:00 pm", PrescriptionName: "Paracetamol", Diseas: "Fever", },
    { appointmentDate: "20/01/2023", time: "7:00 pm", PrescriptionName: "Paracetamol", Diseas: "Fever", },
    { appointmentDate: "20/01/2023", time: "7:00 pm", PrescriptionName: "Paracetamol", Diseas: "Fever", },

  ])

  return (
    <div className='mt-8'><ThemeProvider theme={defaultMaterialTheme}>
      <MaterialTable columns={columns} data={tableData} title="All Prescription" options={{ exportButton: true, headerStyle: { background: "#F3F6F9", color: "#2B3B53", fontSize: "16px" } }}
      />
    </ThemeProvider></div>
  )
}

export default PrescriptionProfile;
