import React from "react";
import { Link } from "react-router-dom";

function BillingProfile() {
  const data = [
    {
      id: 1,
      BillNo: "24",
      Date: "10/02/2022",
      Amount: "215",
      Mode:'cash'
    },
    {
      id: 2,
      BillNo: "24",
      Date: "10/02/2022",
      Amount: "215",
      Mode:'cash'
    },
    {
      id: 3,
      BillNo: "24",
      Date: "10/02/2022",
      Amount: "215",
      Mode:'cash'
    },
  ];
  return (
    <div>
      <section>
        {" "}
        <button className="bg-[#1BC5BD] text-white py-3 px-8 rounded-xl mt-10  ml-[670px] ">
          + Add New
        </button>
      </section>
      <section>
        <div className="flex w-[800px] ml-6 gap-[124px] pl-5 pt-5 mt-6 rounded-lg h-16 bg-[#F3F6F9] ">
          {" "}
          <span>Bill No.</span>
          <span> Date</span>
          <span>Amount</span>
          <span>Mode</span>
          <span>Action</span>
        </div>
      </section>

      <section>
        {data.map((item, index) => {
          return (
            <>
              <div className="flex border-b border-[#0000000d] border-solid pt-6 pl-6">
            
                <div className=" border-b border-[#0000000d] ml-6">
                  <span className="text-sm text-[#66256B]/70 font-medium">
                    {item.BillNo}
                  </span>{" "}
                </div>
                <div className=" border-b border-[#0000000d] ml-36">
                  <span className="text-sm text-[#66256B]/70 font-medium">
                    {item.Date}
                  </span>
                </div>
                <div className=" border-b border-[#0000000d] ml-28">
                  <span className="text-sm text-[#66256B]/70 font-medium">
                    {item.Amount}
                  </span>
                </div>
                <div className=" border-b border-[#0000000d] ml-[150px]">
                  <span className="text-sm text-[#66256B]/70 font-medium">
                    {item.Mode}
                  </span>
                </div>

                <div>
                  <div className="flex border-b border-[#0000000d] border-solid ml-20">
                    <div className="flex mx-auto">
                    <Link to={`/appointments/edit_appointment/${item.id}`}>
                        <div className="flex cursor-pointer bg-[#C9F7F5]/50 w-9 h-9 mx-1 rounded-md">
                          <img
                            className=" w-auto self-center m-auto"
                            src="image/viewicone.svg"
                            alt="view"
                          />
                        </div>
                        </Link>
                      <Link to={`/appointments/edit_appointment/${item.id}`}>
                        <div className="flex cursor-pointer bg-[#C9F7F5]/50 w-9 h-9 mx-1 rounded-md">
                          <img
                            className=" w-auto self-center m-auto"
                            src="image/editicone.svg"
                            alt="edit"
                          />
                        </div>
                      </Link>
                      <div className="flex cursor-pointer bg-[#FF2C53]/10 w-9 h-9 mx-1 rounded-md">
                        <img
                          className=" w-auto self-center m-auto"
                          src="image/deleteicone.svg"
                          alt="delete"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="flex border-b border-[#0000000d] border-solid justify-center p-6">
              <span className="text-sm text-[#66256B]/70 font-medium">
                {item.assignedDoctor}
              </span>
            </div> */}
              {/* <div className="flex border-b border-[#0000000d] border-solid justify-center p-6">
              <span className="text-sm text-[#66256B]/70 font-medium">
                {item.appointmentDate}
              </span>
            </div> */}
              {/* <div className="flex border-b border-[#0000000d] border-solid justify-center p-6">
              <span className="text-sm text-[#66256B]/70 font-medium">
                {item.appointmentTime}
              </span>
            </div> */}
            </>
          );
        })}
      </section>
    </div>
  );
}

export default BillingProfile;
