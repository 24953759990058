import { React, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart } from "chart.js/auto";

function AppointmentStatusChart() {
  const [userData, setuserData] = useState({
    labels: ["Completed", "Pending", "Cancelled"],
    datasets: [
      {
        label: "My First Dataset",
        data: [35, 35, 40],
        backgroundColor: ["#209F84", "#FFC42C", "#FF2C53"],
        hoverOffset: 5,
      },
    ],
  });

  const [chartOptions, setChartOptions] = useState({
    plugins: {
      legend: {
        display: false,
      },
    },
  });

  return (
    <>
      <div className="m-12 mb-18 bg-white w-11/12 rounded-lg">
        <div className="flex justify-between p-8 pl-14 pb-0 ">
          <div className="">
            <h2 className="leading-6 font-semibold text-pulse-gray text-xl ">
              Appointments Status
            </h2>
          </div>

          <div className=" mr-14 mt-4">
            <h1 className="leading-8 font-semibold text-pulse-gray/50 text-md ">
              Total Appointments
            </h1>
            <h2 className="leading-6 font-semibold text-purple-500 text-xl ">
              70,210 People
            </h2>
          </div>
        </div>

        <div className="flex ">
          <div
            style={{ width: "25rem" }}
            className="pb-4 mt-2 pr-16 self-center m-auto"
          >
            <Doughnut data={userData} options={chartOptions} />

            <div className=" text-center relative bottom-48">
              <h2 className="leading-7 font-semibold text-pulse-chart-green text-lg ">
                Completed
              </h2>
              <h2 className="leading-7 font-semibold text-pulse-chart-green text-2xl ">
                22k
              </h2>
            </div>
          </div>

          <div className=" pt-36 pr-20">
            <div className="flex m-5">
              <div className="w-8 h-8 rounded-md bg-green-500 hover:bg-pulse-chart-green/80"></div>
              <h2 className="ml-4 self-center font-semibold text-pulse-gray ">
                Completed
              </h2>
            </div>

            <div className="flex m-5">
              <div className="w-8 h-8 rounded-md bg-red-500 hover:bg-pulse-chart-red/80"></div>
              <h2 className="ml-4 self-center font-semibold text-pulse-gray ">
                Cancelled
              </h2>
            </div>

            <div className="flex m-5">
              <div className="w-8 h-8 rounded-md bg-yellow-500 hover:bg-pulse-chart-yellow/80 "></div>
              <h2 className="ml-4 self-center font-semibold text-pulse-gray">
                Pending
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppointmentStatusChart;
