import React from 'react'

function DoctoreProfile() {
    return (
        <div><div className=" ">
            <div className="mt-4 xs:gap-4 xs:flex ">
                <img
                    className="ml-24 xs:h-16 xs:ml-6"
                    src="image/profilePatient.jpg"
                    alt="patientImage"
                ></img>
                <p className="text-center xs:text-sm  mt-6">Dr. David Morse</p>

            </div>
            <div className=" pl-10 xs:pl-6  xs:mt-2 mt-6 border-gray-4 py-8">
                <p className="xs:text-xs">
                    Phone :
                    <span className="text-[#000000] pl-6 text-[14px] xs:text-xs opacity-50">+91854875152</span>
                </p>

                <p className=" xs:text-xs pt-4">
                    Age :
                    <span className="text-[#000000] pl-12 text-[14px] xs:text-xs opacity-50">21 Year</span>
                </p>
                <p className=" xs:text-xs pt-4">
                    email :
                    <span className="text-[#000000] pl-8 text-[14px] xs:text-xs opacity-50">test@example.com</span>
                </p>
            </div>
        </div></div>
    )
}

export default DoctoreProfile