import React from "react";

function StatusCard() {
  return (
    <div>
      <div className=" mx-14 xs:mx-1 xs:gap-2 mt-10 grid xs:grid-cols-1 grid-cols-12">
        <div className="  col-span-6">
          <div className="border-2 hover:shadow-xl hover:duration-200 hover:scale-[1.02]  bg-red-500 rounded-2xl xs:rounded-md xs:w-[330px] xs:mx-[10px] xs:h-[120px] w-[500px] h-[140px]">
            <div className="flex ">
              {" "}
              <img className="my-6 xs:hidden ml-6" src="image/dashappoint.svg"></img>{" "}
              <h2 className=" text-xl text-white xs:pl-10 my-6 pl-32">
                Todays Appointment <br />{" "}
                <h4 className="mt-2  text-4xl font-medium text-end first-line xs:text-start xs:text-3xl">254</h4>{" "}
              </h2>{" "}
            </div>
          </div>
        </div>
        <div className=" col-span-6">
          <div className="border-2 hover:scale-[1.02] hover:duration-200 hover:shadow-xl bg-green-500 rounded-2xl xs:rounded-md xs:w-[330px] xs:mx-[10px] xs:h-[120px] h-[140px]">
            <div className="flex ">
              {" "}
              <img className="my-6 xs:hidden ml-6" src="image/hospitalErn.svg"></img>{" "}
              <h2 className="xs:pl-10 text-xl text-white my-6 pl-48">
                Hospital Earning <br />{" "}
                <h4 className="mt-2 text-4xl xs:text-start  font-medium text-end xs:text-3xl">₹ 2575K</h4>{" "}
              </h2>{" "}
            </div>
          </div>
        </div>
        <div className=" mt-6 xs:mt-0 col-span-6">
          <div className="border-2  hover:scale-[1.02] hover:duration-200 hover:shadow-xl  bg-blue-500 rounded-2xl xs:rounded-md xs:w-[330px] xs:mx-[10px] xs:h-[120px] w-[500px] h-[140px]">
            <div className="flex ">
              {" "}
              <img className="my-6 xs:hidden ml-6" src="image/dashpatie.svg"></img>{" "}
              <h2 className="xs:pl-10 text-xl text-white my-6 pl-52">
                Total Patient <br />{" "}
                <h4 className="mt-2 text-4xl xs:text-start font-medium text-end xs:text-3xl">112</h4>{" "}
              </h2>{" "}
            </div>
          </div>
        </div>
        <div className="mt-6 xs:mt-0 col-span-6">
          <div className="border-2 hover:scale-[1.02] hover:duration-200 hover:shadow-xl  bg-purple-500 rounded-2xl xs:rounded-md xs:w-[330px] xs:mx-[10px] xs:h-[120px] h-[140px]">
            <div className="flex ">
              {" "}
              <img className="my-6 xs:hidden ml-6" src="image/dashdoc.svg"></img>{" "}
              <h2 className="xs:pl-10 text-xl text-white my-6 pl-72">
                Doctors <br />{" "}
                <h4 className="mt-2 text-4xl xs:text-start font-medium text-end xs:text-3xl">5K</h4>{" "}
              </h2>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusCard;
