import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../shared/components/Header/Header";
import AppointmentDetails from "./AppointmentDetails";

function AddAppointment() {
 
  const [PatientData, SetPatientData] = useState({
    full_Name: "",
    date:"",
    gender: "",
    guardian_name: "",
    email: "",
    Mobile_Number: "",
    Marital_Status: "",
    age: "",
    address: "",
    amount: "",
  });

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    SetPatientData({ ...PatientData, [name]: value });
   
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    alert(PatientData.full_Name);
    console.log(PatientData);


 
  };

  return (
    <div className="">
      <patients className=" " id="dashboardPage">
        <form onSubmit={handleSubmit}>
          <div className="m-7 xs:mx-0 xs:mt-8 mt-10 mb-18 bg-white rounded-lg">
            <div className="flex xs:flex-col p-6 pl-8  ">
              <div className="">
                <h2 className=" text-pulse-gray leading-6 font-medium text-xl ">
                  Add New Appointment
                </h2>
              </div>
              
              <div className=" xs:pl-0 pt-7 pl-28 gap-6 flex">
                <label className="pt-2">Type</label>
                <select
                  className="bg-input-500 rounded-xl px-4 border-inputBorder-500 border-2 h-10 w-[200px]"
                  placeholder="Select "
                  type="select"
                  name="type"
                >
                  <option value="select">Select</option>
                  <option className="placeholder:p-4 " value="OPD">
                    OPD
                  </option>
                  <option value="IPD">IPD</option>
                </select>
              </div>
            </div>
            <div className="  mt-6 ml-8 xs:grid-cols-1 grid grid-cols-3">
              <div className="col-span-1">
                <h4 className="text-inputText-500 font-medium">Full Name</h4>
                <input
                  className="bg-input-500 px-4 mt-4 rounded-xl  border-inputBorder-500 border-2 h-12 xs:w-[280px] w-[300px]"
                  type="text"
                  name="full_Name"
                  onChange={handleInputs}
                  value={PatientData.full_Name}
                  placeholder=" Enter Full Name"
                ></input>
              </div>
              <div className=" xs:mt-4  col-span-1">
                <h4 className="text-inputText-500 font-medium">
                  Guardian Name
                </h4>
                <input
                  className="bg-input-500 px-4   mt-4 rounded-xl  border-inputBorder-500 border-2    xs:w-[280px]  h-12 w-[300px]"
                  onChange={handleInputs}
                  value={PatientData.guardian_name}
                  type="text"
                  name="guardian_name"
                  placeholder="Enter Guardian Name"
                ></input>
              </div>
              <div className=" xs:mt-4 col-span-1">
                <h4 className="text-inputText-500 font-medium">Email</h4>
                <input
                  className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2   xs:w-[280px] h-12 w-[300px]"
                  onChange={handleInputs}
                  value={PatientData.email}
                  type="email"
                  name="email"
                  placeholder="Enter Email Address"
                ></input>
              </div>
              <div className=" mt-8 xs:mt-4 col-span-1">
                <h4 className="text-inputText-500 font-medium">Gender</h4>
                <select
                  className="bg-input-500 mt-4 rounded-xl px-4 border-inputBorder-500 border-2  xs:w-[280px] h-12 w-[300px]"
                  onChange={handleInputs}
                  value={PatientData.gender}
                  type="select"
                  name="gender"
                  placeholder="Select "
                >
                  <option value="select">Select</option>
                  <option className="placeholder:p-4 " value="Male">
                    Male
                  </option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className=" mt-8 col-span-1">
                <h4 className="text-inputText-500 font-medium">
                  Mobile Number
                </h4>
                <input
                  className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2  xs:w-[280px] h-12 w-[300px]"
                  onChange={handleInputs}
                  value={PatientData.Mobile_Number}
                  type="number"
                  name="Mobile_Number"
                  placeholder="Enter Mobile Number"
                ></input>
              </div>
              <div className=" mt-8 col-span-1">
                <h4 className="text-inputText-500 font-medium">
                  Marital Status
                </h4>
                <input
                  className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 border-2 xs:w-[280px] h-12 w-[300px]"
                  onChange={handleInputs}
                  value={PatientData.Marital_Status}
                  type="text"
                  name="Marital_Status"
                  placeholder="Select"
                ></input>
              </div>
              <div className=" mt-8  col-span-1">
                <h4 className="text-inputText-500 font-medium">
                  Date of Birth
                </h4>
                <input
                  type="date"
                  name="date"
                  id="date"
                  onChange={handleInputs}
                  value={PatientData.date}
                  className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500  xs:w-[280px] border-2 h-12 w-[300px]"
                  placeholder="mm/dd/yy"
                ></input>
              </div>
              <div className=" mt-8 col-span-1">
                <h4 className="text-inputText-500 font-medium">Age</h4>
                <input
                  className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 xs:w-[280px] border-2 h-12 w-[300px]"
                  onChange={handleInputs}
                  value={PatientData.age}
                  type="text"
                  name="age"
                  placeholder="Enter Age"
                ></input>
              </div>
              <div className=" mt-8 col-span-1">
                <h4 className="text-inputText-500 font-medium">Address</h4>
                <input
                  className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 xs:w-[280px] border-2 h-12 w-[300px]"
                  onChange={handleInputs}
                  value={PatientData.address}
                  type="text"
                  name="address"
                  placeholder="Enter Address"
                ></input>
              </div>
              <div className=" mt-8 col-span-1">
                <h4 className="text-inputText-500 font-medium">Amount</h4>
                <input
                  className="bg-input-500 px-4  mt-4 rounded-xl  border-inputBorder-500 xs:w-[280px] border-2 h-12 w-[300px]"
                  onChange={handleInputs}
                  value={PatientData.amount}
                  type="number"
                  name="amount"
                  placeholder="Enter Amount"
                ></input>
              </div>
              <div className=" mt-8 col-span-1">
                <h4 className="text-inputText-500 font-medium">
                  Upload Documents (If Any)
                </h4>
                <input
                  class="relative w-[300px] xs:w-[280px]  mt-4 rounded-xl  m-0 block  min-w-0 flex-auto cursor-pointer  border border-solid border-neutral-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-green-500 file:px-3 file:py-3 file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-neutral-200 focus:border-primary focus:bg-white focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
                  type="file"
                  name="file"
                  
                  id="formFile"
                />
              </div>
            </div>
            <div>
              <AppointmentDetails />
            </div>

            {/* Save/Cancel */}
            <div className="flex pb-8 pt-4 mx-8  flex-wrap">
              <button
                type="submit"
                className="flex m-2  cursor-pointer h-10  justify-between px-8 rounded-lg font-medium text-sm bg-[#209F84] text-white"
              >
                <span className="self-center m-auto">Save and Print</span>
              </button>

              <Link to="/AddPatient">
                <div className="flex m-2 cursor-pointer  h-10 w-28  justify-between px-5 rounded-lg font-medium text-sm border-2 border-[#209F84]">
                  <span className="self-center m-auto  text-[#209F84]">
                    Cancel
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </form>
      </patients>
    </div>
  );
}

export default AddAppointment;
