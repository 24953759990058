import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import AddAppointment from '../components/AppointmentPage/AddAppointment'
import Appointment from '../components/AppointmentPage/Appointment'
import EditAppointment from '../components/AppointmentPage/EditAppointment'
import Dashboard from '../components/DashboardPage/Dashboard'
import Doctore from '../components/DoctorePage/Doctore'
import AddPatient from '../components/PatientsPage/AddPatient'
import Patient from '../components/PatientsPage/Patient'
import ManagePrescription from '../components/PrescriptionPage/ManagePrescription'
import Prescription from '../components/PrescriptionPage/Prescription'
import Header from '../shared/components/Header/Header'
import Sidebar from '../shared/components/SidebarPage/Sidebar'



import CreateNewPass from '../components/Login/CreateNewPass'
import ForgotPassword from '../components/Login/ForgotPassword'
import Login from '../components/Login/Login'
import VerificationCode from '../components/Login/VerificationCode'
import AllPrescription from '../components/PrescriptionPage/AllPrescription'
import PatientProfile from '../components/PatientProfilePage/PatientProfile'
import Setting from '../components/settingPage/Setting'

function Page() {

  const isLogin = true;

  return (
    <>

      <BrowserRouter>
       
       {isLogin === true
       ? <Sidebar>
       <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/doctor" element={<Doctore />} />
            <Route path="/patient" element={<Patient />} />
            <Route path="/prescription" element={<Prescription />} />
            <Route path="/AddAppointment" element={<AddAppointment />} />
            <Route path="/EditAppointment" element={<EditAppointment />} />
            <Route path="/AddPatient" element={<AddPatient />} />
            <Route path="/ManagePrescription" element={<ManagePrescription />} />
            <Route path="/Allprescription" element={<AllPrescription />} />
            <Route path="/Setting" element={<Setting/>} />
            <Route path="/PatientProfile" element={<PatientProfile/>} />
          </Routes>
        </Sidebar> : 
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/forgot' element={<ForgotPassword />} />
          <Route path='/verification' element={<VerificationCode/>} />
          <Route path='/createpassword' element={<CreateNewPass/>} />
        </Routes>
        }
        


      </BrowserRouter>
    </>
  )
}

export default Page