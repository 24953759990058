import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "../Header/Header";
import './sidebar.css'

export const   menuOptions  = [
  {
    id: 1,
    listName: "Dashboard",
    image: "image/dashboard icone.svg",
    image2: "image/dashboard-active.svg",
    listUrl: "/dashboard",
  },
  {
    id: 2,
    listName: "Appointments",
    image: "image/appiontmenticone.svg",
    listUrl: "/appointment",
  },

  {
    id: 3,
    listName: "Patients",
    image: "image/patienticone.svg",
    listUrl: "/patient",
  },
  {
    id: 4,
    listName: "Prescription",
    image: "image/prescription.svg",
    listUrl: "/prescription",
  },
  {
    id: 5,
    listName: "All Prescription",
    image: "image/allPrescription.svg",
    listUrl: "/Allprescription",
  },
  {
    id: 6,
    listName: "Setting",
    image: "image/settingIcone.svg",
    listUrl: "/Setting",
  },
];


function Sidebar({ children }) {
  const [isOpen, setIsOpen] = useState("");




  const displayIcone = true;

  return (
    <div className=" w-screen xs:grid-cols-8 grid grid-cols-10">
      <div className="  xs:hidden mt-8 bg-white pl-6  col-span-2">
        <div className="flex justify-between">
          {" "}
          <h1 className="text-2xl  font-bold text-dark-500">5TechG Lab</h1>{" "}
          {/* <img className="px-4" src="image/toggle.svg"></img> */}
        </div>
        <div className="mt-16">
          {menuOptions.map((item, index) => (
            <NavLink
              className="  flex hover:bg-[#209F84] hover:text-white hover:rounded-2xl px-4 my-2 py-4 text-light-100 mr-4 mt-4"
              id="link"
              to={item.listUrl}
              key={index}
            >
              <img className="pr-6" src={item.image}></img>
              <h1 className="text-lg  font-semibold">{item.listName}</h1>
            </NavLink>
          ))}
        </div>
      </div>
   

      <main className="col-span-8  bg-[#FBFBFB] grid overflow-hidden ">
      
        <div>
          <Header />
        </div>
        <div className="overflow-scroll scrollbar-hidden"> {children}</div>
      </main>
    </div>
  );
}

export default Sidebar;
