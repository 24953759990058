import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import AppointmentTable from "./AppointmentTable";

function AppointmentProfile() {


  // const data = [
  //   {
  //     id: 1,
  //     BookingDate: "10/02/2020",
  //     notes: "fever problem",
  //     appointmentDate: "12/12/2019",
  //     appointmentTime: "12:00 PM",
  //     appointmentStatus: "Pending",
  //   },
  //   {
  //     id: 2,
  //     BookingDate: "10/02/2020",
  //     notes: "fever problem",
  //     appointmentDate: "12/12/2019",
  //     appointmentTime: "12:00 PM",
  //     appointmentStatus: "complete",
  //   },
  //   {
  //     id: 3,
  //     BookingDate: "10/02/2020",
  //     notes: "fever problem",
  //     appointmentDate: "12/12/2019",
  //     appointmentTime: "12:00 PM",
  //     appointmentStatus: "Pending",
  //   },

  // ];
  return (
    <div>
      <section>
        <form className="mt-8 ml-5">
          <input
            className=" xs:w-[290px] w-[210px] h-[46px] pr-4 pl-5 ml-4 rounded-xl shadow-md "
            placeholder="Appointment Date "
            type="date"
            name="date"
            id="date"

          ></input>
          <input
            className="xs:w-[290px] w-[200px] h-[46px] pl-5 pr-2 xs:ml-4 xs:mt-4 ml-6 rounded-xl shadow-md "
            type="time"
            name="time"


          ></input>
          <input
            className="w-[200px] xs:ml-4 xs:mt-4  h-[46px] pl-5 ml-6 rounded-xl shadow-md "
            type="text"
            name="Disease"
            placeholder="Disease "

          ></input>
          <button
            type="submit"
            className="ml-6 rounded-xl bg-[#209F84] hover:bg-[#1B846D]  py-2 px-4 text-white"
          >
            Save
          </button>
        </form>
      </section>
      {/* <table className=" whitespace-nowrap overflow-auto bg-white">
        <section>
          <div className="flex w-[800px] ml-6 gap-24 pl-5 pt-5 mt-6 rounded-lg h-16 bg-[#F3F6F9] ">
            {" "}

            <span>Appt. Date</span>
            <span>Booking Date</span>
            <span>Disease</span>
            <span>Status</span>
            <span>Action</span>
          </div>
        </section>

        <section>
          {data.map((item, index) => {
            return (
              <>
                <div className="flex border-b border-[#0000000d] border-solid pt-6 pl-6">
                  <div className=" border-b border-[#0000000d] ml-6">
                    <span className="text-sm text-[#66256B]/70 font-medium">
                      {item.appointmentDate}
                    </span> <br />
                    <span className="text-blue-400">{item.appointmentTime}</span>
                  </div>
                  <div className=" border-b border-[#0000000d] ml-24">
                    <span className="text-sm text-[#66256B]/70 font-medium">
                      {item.BookingDate}
                    </span>
                  </div>
                  <div className=" border-b border-[#0000000d] ml-24">
                    <span className="text-sm text-[#66256B]/70 font-medium">
                      {item.notes}
                    </span>
                  </div>
                  <div className="-mt-5 ml-12">

                    {item.appointmentStatus === "Pending" ? (
                      <div className="flex border-b border-[#0000000d] border-solid justify-center pt-5 pb-6 px-3">
                        <span className="w-28 text-sm h-9  rounded-lg bg-[#ffc42c30] flex items-center text-[#FFC42C] font-medium">
                          <div className="mx-auto">{item.appointmentStatus}</div>
                        </span>
                      </div>
                    ) : item.appointmentStatus === "Cancelled" ? (
                      <div className="flex border-b border-[#0000000d] border-solid justify-center pt-5 pb-6 px-3">
                        <span className="w-28 text-sm h-9 p-4 rounded-lg bg-[#ff2c5338] flex items-center text-[#FF2C53] font-medium">
                          <div className=" mx-auto">{item.appointmentStatus}</div>
                        </span>
                      </div>
                    ) : (
                      <div className="flex border-b border-[#0000000d] border-solid justify-center pt-5 pb-6 px-3">
                        <span className="w-28 text-sm h-9 p-4 rounded-lg bg-[#C9F7F5] flex items-center text-[#1BC5BD] font-medium">
                          <div className="mx-auto">{item.appointmentStatus}</div>
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="flex border-b border-[#0000000d] border-solid ml-6">
                      <div className="flex mx-auto">
                        <Link to={`/appointments/edit_appointment/${item.id}`}>
                          <div className="flex cursor-pointer bg-[#C9F7F5]/50 w-9 h-9 mx-1 rounded-md">
                            <img
                              className=" w-auto self-center m-auto"
                              src="image/editicone.svg"
                              alt="edit"
                            />
                          </div>
                        </Link>
                        <div className="flex cursor-pointer bg-[#FF2C53]/10 w-9 h-9 mx-1 rounded-md">
                          <img
                            className=" w-auto self-center m-auto"
                            src="image/deleteicone.svg"
                            alt="delete"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </section>

      </table> */}
      <div className="mt-10"><AppointmentTable/></div>
    </div>
  );
}

export default AppointmentProfile;
